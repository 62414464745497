import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Logo from "shared/Logo/Logo";
// import SocialsList from "shared/SocialsList/SocialsList";
// import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";

//Context
import { AuthContext } from "context/authenticate";

export interface WidgetFooterMenu {
	id: string;
	title: string;
	menus: CustomLink[];
}

// const widgetMenus: WidgetFooterMenu[] = [
//   {
//     id: "5",
//     title: "What we carry",
//     menus: [
//       { href: "#", label: "Geoduck" },
//       { href: "#", label: "King Crab" },
//       { href: "#", label: "Razor Claims" },
//       { href: "#", label: "Abalone" },
//       { href: "#", label: "Other" },
//       // { href: "#", label: "Utility-First" },
//       // { href: "#", label: "Dark Mode" },
//       // { href: "#", label: "Responsive Design" },
//     ],
//   },
//   {
//     id: "1",
//     title: "Explore",
//     menus: [
//       // { href: "#", label: "Design features" },
//       // { href: "#", label: "Prototyping" },
//       // { href: "#", label: "Design systems" },
//       { href: "#", label: "Pricing" },
//       { href: "#", label: "Customers" },
//       { href: "#", label: "Security" },
//       // { href: "#", label: "Integrations" },
//       { href: "#", label: "Contact" },
//     ],
//   },
//   {
//     id: "2",
//     title: "Resources",
//     menus: [
//       // { href: "#", label: "Best practices" },
//       // { href: "#", label: "Support" },
//       // { href: "#", label: "Developers" },
//       // { href: "#", label: "Learn design" },
//       { href: "#", label: "What's new" },
//       { href: "#", label: "Releases" },
//       { href: "#", label: "Careers" },
//       { href: "#", label: "About us" },
//     ],
//   },
//   {
//     id: "4",
//     title: "Community",
//     menus: [
//       // { href: "#", label: "Discussion Forums" },
//       { href: "#", label: "Code of Conduct" },
//       // { href: "#", label: "Community Resources" },
//       { href: "#", label: "Contributing" },
//       // { href: "#", label: "Concurrent Mode" },
//       // { href: "#", label: "API Reference" },
//       // { href: "#", label: "Advanced Guides" },
//       // { href: "#", label: "Main Concepts" },
//     ],
//   },
// ];

const Footer: React.FC = () => {
	const { companyLogo, companyName, homePageUrl, showNavItems } = useContext(AuthContext);

	const d = new Date();
	const year = d.getFullYear();

	// const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
	//   return (
	//     <div key={index} className="text-sm">
	//       <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
	//         {menu.title}
	//       </h2>
	//       <ul className="mt-5 space-y-4">
	//         {menu.menus.map((item, index) => (
	//           <li key={index}>
	//             <a
	//               key={index}
	//               className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
	//               href={item.href}
	//             >
	//               {item.label}
	//             </a>
	//           </li>
	//         ))}
	//       </ul>
	//     </div>
	//   );
	// };

	return (
		<div className="nc-Footer relative py-24 lg:py-32 border-t border-neutral-200 dark:border-neutral-700">
			<div className="container flex-direction-column items-center justify-center">
				<div className="container flex justify-center">
					<Logo img={companyLogo} homePageUrl={homePageUrl} />
				</div>

				{/* {widgetMenus.map(renderWidgetMenuItem)} */}

				{showNavItems ? (
					<ul className="mt-10 container flex justify-center">
						<li
							className="inline-flex items-center text-sm xl:text-base font-normal text-neutral-700 dark:text-neutral-300 py-2 px-4 xl:px-5 rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200 text-center"
							style={{ fontSize: "12px" }}
						>
							<NavLink
								exact
								strict
								className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
								rel="noopener noreferrer"
								to={{
									pathname: "/",
								}}
							>
								{"全部產品"}
							</NavLink>
							{/* {homePageUrl === "" && (
                <NavLink
                  exact
                  strict
                  className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                  rel="noopener noreferrer"
                  to={{
                    pathname: "/",
                  }}
                >
                  {"全部產品"}
                </NavLink>
              )} */}

							{/* {homePageUrl !== "" && (
                <a
                  className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                  rel="noopener noreferrer"
                  href={homePageUrl}
                >
                  {"主頁"}
                </a>
              )} */}
						</li>
						{/* <li>
                            <a 
                              className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                              href="/whatWeCarry"
                            >
                              {t("NavItems.whatWeCarry")}
                            </a>
                          </li>
                          <li>
                            <a 
                              className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                              href="/products"
                            >
                              {t("NavItems.products")}
                            </a>
                          </li> */}
					</ul>
				) : null}

				{/* <div className="mt-5 flex justify-center">
            <SocialsList />
          </div> */}

				<span
					className="
              mt-8 
              flex 
              justify-center 
              text-center
              text-neutral-6000 
              dark:text-neutral-300
            "
					style={{ fontSize: "12px" }}
				>
					Copyright 2024 by Evertop Overseas (Asia) Limited. All Rights Reserved.
				</span>

				<span
					className="
              flex 
              justify-center 
              text-center
              text-neutral-6000 
              dark:text-neutral-300
            "
					style={{ fontSize: "12px" }}
				>
					Powered by <a href="https://taotaro.app/en-US">&nbsp;TaoTaro's&nbsp;</a>RapidKart
				</span>
			</div>
		</div>
	);
};

export default Footer;
