import React, { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "components/Spinner/Spinner";
import OneButtonCommonLayout from "containers/PageAddListing1/OneButtonCommonLayout";
import { AuthContext } from "context/authenticate";
import { CartContext } from "context/cart";
import { Helmet } from "react-helmet";

//API
import Axios from "axios";
import { url } from "../../utils/constant";

export interface PayMeMobileConfirmProps {
  className?: string;
}

const PayMeMobileConfirmPage: FC<PayMeMobileConfirmProps> = ({ className = "" }) => {
  //id is our own orderID

  const { id } = useParams() as any;

  const { companyName } = useContext(AuthContext);
  const { clearCart } = useContext(CartContext);

  const [loading, setLoading] = useState(true);

  const [successful, setSuccessful] = useState(false);
  const [unSuccessful, setUnsuccessful] = useState(false);

  const [submissionId, setSubmissionId] = useState("");

  const paymePaymentQuery = async (merchantReference: any) => {
    try {
      let data = {
        customOrderId: merchantReference,
        paymentMethod: "PayMe",
      };

      // const result = await Axios.post(`${url}/rapidcart/web/utils/paymePaymentQuery`, data);
      const result = await Axios.post(`${url}/rapidcart/web/receipt/thirdPartyAfterPay`, data);

      console.log("PayMe Payment Query Result:", result.data);

      let response = result.data;

      if (response.data.outOrderInfo && response.data.outOrderInfo.transactions) {
        setSubmissionId(response.data.orderInfo.transactionId);
      }

      return response;
    } catch (err) {
      console.log("PayMe Payment Query Error:", err);
    }
  };

  // const emailReceipt = async (
  //   response: any,
  // ) => {
  //   try {
  //     let productOrderArray: any = [];
  //     let coupon: any;

  //     let data = {
  //       customerName: response.data.orderInfo.name,
  //       customerEmail: response.data.orderInfo.email,
  //       customerPhone: response.data.orderInfo.phone,
  //       customerAddress: response.data.orderInfo.address,
  //       referenceNo: response.data.orderInfo.transactionId,
  //       productOrder: productOrderArray,
  //       deliveryFee: response.data.orderInfo.deliveryFee,
  //       subtotal: response.data.orderInfo.originTransactionTotal,
  //       total: response.data.orderInfo.transactionTotal,
  //       promotionAmount: coupon,
  //       paymentMethod: response.data.orderInfo.paymentMethod,
  //     };

  //     if (response.data.orderInfo.couponCode === null) {
  //       data.promotionAmount = "不適用";
  //     } else {
  //       data.promotionAmount = response.data.orderInfo.couponCode;
  //     }

  //     let products = response.data.orderInfo.skuList;

  //     products.forEach((item: any) => {
  //       data.productOrder.push({
  //         title: item.skuName,
  //         remarks: "",
  //         amount: item.buyCount,
  //         price: item.skuSalePrice,
  //         subTotal: item.buyCount * item.skuSalePrice,
  //       });
  //     });

  //     const result = await Axios.post(`${url}/rapidcart/web/utils/emailReceipt`, data);

  //     console.log('Email Result:', result);

  //     return result;

  //   } catch (err) {
  //     console.log('Email Receipt Error:', err);
  //   }
  // };

  const executeReturn = async () => {
    const response = await paymePaymentQuery(id);

    if (response?.status === "success" && response.data.outOrderInfo.transactions) {
      // await emailReceipt(response);

      setTimeout(() => {
        setLoading(false);
        setSuccessful(true);
        clearCart();
      }, 300);
    } else {
      setLoading(false);
      setUnsuccessful(true);
    }
  };

  useEffect(() => {
    executeReturn();
  }, []);

  return (
    <main className="container mt-11 mb-24 lg:mb-32 flex justify-center lg:flex-row">
      <Helmet>
        <title>{companyName}</title>
      </Helmet>

      {loading === true && <Spinner className="pt-24 pb-24 lg:py-32" />}

      {loading === false && successful === true && (
        <OneButtonCommonLayout backtHref="/">
          <h2 className="text-3xl lg:text-4xl font-semibold">{`感謝你購買${companyName}產品`}</h2>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <h3 className="text-2xl font-semibold">
            {`你已下單成功，你的訂單編號為：${submissionId}，我們將於3個工作天內為你安排送貨。`}
          </h3>
        </OneButtonCommonLayout>
      )}

      {loading === false && unSuccessful === true && (
        <OneButtonCommonLayout backtHref="/">
          <h2 className="text-3xl lg:text-4xl font-semibold">{"好像有錯誤"}</h2>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <h3 className="text-2xl font-semibold">{"您的提交或付款失敗。請再試一次。如果問題仍然存在，請聯繫我們。"}</h3>
        </OneButtonCommonLayout>
      )}
    </main>
  );
};

export default PayMeMobileConfirmPage;
