import React, { useEffect } from "react";
import { update_qrcode } from "./generate";
// import paymeLogo from "../../../images/Icon-192.png";
import qrcodeLogo from "../../../images/evertopLogo.jpeg";

const QrCodeGen = ({ url }) => {
  // const defaultUrl = 'https://qr.payme.hsbc.com.hk/2/ThisIsAnExamplePayCode';

  useEffect(() => {
    // console.log('url change!')
    update_qrcode();
  }, [url]);

  return (
    <form name="qrForm" onSubmit={() => {
      update_qrcode()
      return false
    }}
    >
      {/* <span>TypeNumber:</span> */}
      {/* <select name="t" onChange={update_qrcode}>
	      <option value="0" selected="selected">Auto Detect</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="40">40</option>
	    </select>
      <span>ErrorCorrectionLevel:</span>
      <select name="e" onChange={update_qrcode}>
        <option value="L">L(7%)</option>
        <option value="M">M(15%)</option>
        <option value="Q" selected="selected">Q(25%)</option>
        <option value="H">H(30%)</option>
      </select>
      <span>Size:</span>
      <input name="size" type="text" value="344"/> */}
      {/* <br/>
      <span>Logo:</span>
	    <input type="file" onchange="encodeImageFileAsURL(this)" /> */}
      <span>
        <img id="logo" width="30" height="30" hidden="hidden" src={qrcodeLogo} alt="" onLoad={update_qrcode} />
      </span>
      {/* <input type="checkbox" name="consumer" value="consumer" onChange={update_qrcode}/> PayMe Style
      <br></br>
       */}
      <textarea name="msg" rows="2" cols="86" value={url} hidden="hidden"></textarea>

      {/* <input className="update-btn" type="button" value="Update" onClick={update_qrcode}/> */}
      {/* <div id="qr"></div> */}
      <canvas id="payCodeCanvas" width="255" height="255"></canvas>
    </form>
  )
}

export default QrCodeGen;