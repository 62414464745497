import React, { FC, useEffect, useContext } from "react";
import SectionGridFilterCard from "./SelectionGridFilterCard";
import { AuthContext } from "context/authenticate";
import { GoogleContext } from "context/google";
import { CartContext } from "context/cart";
import Spinner from "components/Spinner/Spinner";
import { Helmet } from "react-helmet";
// import { helmetDesc } from "./constant";

import Axios from "axios";
import { url } from "../../utils/constant";

export interface ProductsPageProps {
  className?: string;
};

const ProductsPage: FC<ProductsPageProps> = ({className = ""}) => {

  const { companyName } = useContext(AuthContext);

  const {
    discountCodes,
    deliveryOptions,
    setDiscountCodes, 
    setDeliveryOptions 
  } = useContext(CartContext);

  const { loaded } = useContext(GoogleContext);

  const getAppUtil = async () => {
    try {

      const result = await Axios.post(`${url}/rapidcart/web/globalConfig/queryAll`);
      let data = result.data.data.lists;
      
      //Check discount codes and set state
      let promoArray:any = [];

      data.forEach((item: any) => {
        if (item.discountCode && item.discountCode !== null) { 
          if (item.discountAmount !== "0") {
            promoArray.push({
              "discountCode": item.discountCode,
              "discountAmount": item.discountAmount,
            });
          } else {
            promoArray.push({
              "discountCode": item.discountCode,
              "discountPercentage": item.discountPercentage,
            });
          }
        } 

        if (item.deliveryFee !== "0") {
          //Set default delivery fee
          let deliveryData = data[0].deliveryFee;
          let parseDelivery = parseInt(deliveryData);

          if (item.freeDeliveryAmount !== "0") {
            let freeDeliveryData = data[0].freeDeliveryAmount;
            let parseFreeDeliveryAmount = parseInt(freeDeliveryData);

            setDeliveryOptions((deliveryOptions: any) => ({
              ...deliveryOptions,
              freeDeliveryAmount: parseFreeDeliveryAmount,
              deliveryFee: parseDelivery,
              waiveDelivery: 0,
            }));

          } else {

            setDeliveryOptions((deliveryOptions: any) => ({
              ...deliveryOptions,
              freeDeliveryAmount: 0,
              deliveryFee: parseDelivery,
              waiveDelivery: 0,
            }));
          }
        }
      });

      setDiscountCodes(promoArray);

    } catch (err) {
      console.log("getAppUtilError:", err);
    }
  };

  useEffect(() => {
    getAppUtil();
  }, []);

  useEffect(() => {
    window.localStorage.setItem('CURRENT-DISCOUNT', JSON.stringify(discountCodes));
    window.localStorage.setItem('CURRENT-DELIVERY', JSON.stringify(deliveryOptions));
  }, [discountCodes, deliveryOptions]);

  return(
    <div
      className={`nc-ProductsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>{companyName}</title>
        {/* <meta name="description" content={helmetDesc}/>
        <meta property="og:description" content={helmetDesc}/> */}
        {/* <meta property="og:image" content={metaDecorator.hostname}/> */}
        {/* <meta 
          property="og:url" 
          content={window.location.href}
        /> */}
      </Helmet>

      <div 
        className="container relative overflow-hidden"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {
          loaded === undefined && (
            <Spinner className="pt-24 pb-24 lg:py-32" />
          )
        }

        {
          loaded === true && (
            <SectionGridFilterCard className="pb-24 lg:pb-32" />
          )
        }
      </div>
    </div>
  )
};

export default ProductsPage;