import React, { FC, useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
// import SearchDropdown from "./SearchDropdown";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import { ShoppingCartIcon } from "@heroicons/react/solid";
// import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
// import logoImage from "../../images/logo.png";

// Context
import { AuthContext } from "context/authenticate";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const { companyLogo, homePageUrl, showNavItems } = useContext(AuthContext);

  return (
    <div className={`nc-MainNav1 relative z-10 ${isTop ? "onTop " : "notOnTop backdrop-filter"}`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo img={companyLogo} homePageUrl={homePageUrl} />
          {showNavItems ? <Navigation /> : null}
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <button className="text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center">
              <Link to="/cart">
                <ShoppingCartIcon className="w-8 h-8" />
              </Link>
            </button>
            {/* <div className="hidden items-center xl:flex space-x-1">
              <SwitchDarkMode />
              <SearchDropdown />
              <div className="px-1" />
              <ButtonPrimary href="/login">Sign up</ButtonPrimary>
            </div> */}
          </div>
          <div className="flex items-center xl:hidden">
            {/* <SwitchDarkMode /> */}
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
