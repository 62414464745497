import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CommonLayout from "../../containers/PageAddListing1/CommonLayout";
// import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { AuthContext } from "context/authenticate";
import { CartContext } from "context/cart";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";

export interface CartProps {}

function DiscountRulesRender(props: any) {
  const { discountRules } = props;
  const [showAll, setShowAll] = useState<boolean>(false);
  if (!Array.isArray(discountRules) || !discountRules.length) {
    return null;
  }
  const isShowAll = showAll || discountRules.length === 1;
  const displayDiscountRules = isShowAll ? discountRules : discountRules.slice(0, 1);
  return (
    <>
      {displayDiscountRules.map((rule: any, index: number) => {
        return (
          <div
            className="text-s text-neutral-500 dark:text-neutral-400 font-normal"
            style={{ marginTop: "8px" }}
            key={index}
          >
            {rule.type === "OVER_AMOUNT_DISCOUNT"
              ? `每套 ${rule.unitQuantity} 件，總計 HKD $${rule.unitDiscount}`
              : `如果購買${rule.unitQuantity}個或更多，每個單價是 HKD $${rule.unitDiscount}`}
          </div>
        );
      })}
      {!isShowAll ? (
        <span className="text-s" style={{ marginTop: "8px", cursor: "pointer" }} onClick={() => setShowAll(true)}>
          查看更多優惠
        </span>
      ) : null}
    </>
  );
}

// function DiscountDetailRender(props: any) {
// 	const { apiBreakdown } = props;
// 	if (!Array.isArray(apiBreakdown) || !apiBreakdown.length) {
// 		return null;
// 	}
// 	return <>
// 		{
// 			apiBreakdown.map((item: any, index: number) => {
// 				return (
// 					<div
// 						className="text-s text-neutral-500 dark:text-neutral-400 font-normal listingSection__wrap"
// 						key={`${item.inventoryName}-${item.inventoryId}`}
// 						style={{ margin: '12px 0', padding: '12px' }}
// 						// style={{ margin: '12px 0', boxSizing: 'border-box', padding: '12px', border: '1px solid #9195a3', borderRadius: '6px' }}
// 					>
// 						商品：{item.inventoryName}, 总价：{item.subTotal} {item.useInventoryDiscount ? `，使用了商品折扣` : ''}
// 						{
// 							item.skuPriceList.map((sku: any) => {
// 								return (
// 									<div style={{ marginLeft: '12px' }}>
// 										購買了 【{sku.name}】【{sku.count}】件，原價：${sku.totalOriginPrice} 折扣價：${sku.discountPrice}
// 									</div>
// 								)
// 							})
// 						}
// 					</div>
// 				);
// 			})
// 		}
// 	</>
// }

function SubtotalRender(props: any) {
  const { apiBreakdown, id } = props;
  if (!Array.isArray(apiBreakdown) || !apiBreakdown.length) {
    return null;
  }

  return (
    <>
      {apiBreakdown.map((item: any) => {
        return (
          <>
            {item.inventoryId === id && (
              <div className="flex" style={{ margin: "0" }}>
                <div style={{ width: "80%", marginLeft: "12px" }}></div>
                <div className={`flex items-center justify-between`} style={{ width: "11rem" }}>
                  <div
                    className="text-s text-neutral-500 dark:text-neutral-400 font-normal flex justify-end"
                    key={`${item.inventoryName}-${item.inventoryId}`}
                    // style={{ margin: '12px 0', padding: '12px' }}
                    style={{ width: "100%" }}
                    // style={{ margin: '12px 0', boxSizing: 'border-box', padding: '12px', border: '1px solid #9195a3', borderRadius: '6px' }}
                  >
                    {`產品小計：HKD $${item.subTotal}`}
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
    </>
  );
}

const Cart: FC<CartProps> = () => {
  const { companyName } = useContext(AuthContext);

  const {
    cart,
    total,
    subtotal,
    discountCodes,
    inputDiscountCode,
    shippingFee,
    deliveryOptions,
    displayDiscount,
    discountByAmount,
    // discountByPercentage,
    phone,
    cantUseCouponMsg,
    apiBreakdown,
    increaseAmount,
    decreaseAmount,
    handleDiscount,
    handlePhone,
  } = useContext(CartContext);

  let history = useHistory();

  const [freeDelivery, setFreeDelivery] = useState(false);
  const [isOpenModalPopUp, setIsOpenModalPopUp] = useState(false);
  const [phoneErrMsg, setPhoneErrMsg] = useState(false);
  const [enableNext, setEnableNext] = useState(false);

  const { freeDeliveryAmount } = deliveryOptions;

  if (process.env.REACT_APP_STAGE === "production") {
    const tagManagerArgs = {
      dataLayer: {
        event: "add_to_cart",
        currency: "HKD",
        value: total,
        items: cart,
      },
      dataLayerName: "CartDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  const toCustomerInfo = () => {
    history.push("/checkout");
    // if (inputDiscountCode) {
    //   if (discountCodes.length > 0) {
    //     discountCodes.forEach((item: any) => {
    //       if (inputDiscountCode === item.discountCode) {
    //         history.push("/checkout");
    //       } else {
    //         openModalPopUp();
    //       }
    //     });
    //   }
    // } else {
    //   history.push("/checkout");
    // }
  };

  const handlePhoneValidation = (input: string) => {
    if (input !== "") {
      setPhoneErrMsg(false);
      if (input.length === 8) {
        const isNumeric = /^[0-9]+$/.test(input);

        if (isNumeric) {
          setEnableNext(true);
        } else {
          setPhoneErrMsg(true);
          setEnableNext(false);
        }
      } else {
        setEnableNext(false);
        setPhoneErrMsg(true);
      }
    } else {
      setPhoneErrMsg(false);
      setEnableNext(false);
    }
  };

  useEffect(() => {
    if (subtotal > freeDeliveryAmount || subtotal === freeDeliveryAmount) {
      setFreeDelivery(true);
    } else {
      setFreeDelivery(false);
    }
  }, [subtotal, setFreeDelivery]);

  useEffect(() => {
    handlePhoneValidation(phone);
  }, [phone]);

  const closeModalPopUp = () => {
    setIsOpenModalPopUp(false);
  };

  const openModalPopUp = () => {
    setIsOpenModalPopUp(true);
  };

  if (!cart.length) {
    return (
      <CommonLayout backHref="/" nextHref="/" cart={cart}>
        <h2 className="text-2xl font-semibold flex justify-center">{"購物車沒有貨品"}</h2>
      </CommonLayout>
    );
  }

  const renderPopUp = () => {
    return (
      <Transition appear show={isOpenModalPopUp} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModalPopUp}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div
                  className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full"
                  style={{ height: "25%" }}
                >
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                      折扣碼無效
                    </h3>

                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalPopUp} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <div
                      className="flex items-center space-x-8"
                      style={{ paddingTop: "3.5rem", paddingBottom: "3.5rem" }}
                    >
                      <h3 className={`text-2xl text-neutral-6000`}>{"您輸入的折扣碼不正確，請重新檢查"}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const displayCart = cart
    .map((item) => {
      return {
        ...item,
        selectedSkuList: item.selectedSkuList.filter((n: any) => n.count > 0),
      };
    })
    .filter((n) => n.selectedSkuList.length > 0);

  return (
    <CommonLayout
      backHref="/"
      cart={cart}
      onClick={toCustomerInfo}
      enableNext={enableNext}
      reason={cantUseCouponMsg}
      inputCode={inputDiscountCode}
    >
      <Helmet>
        <title>{companyName}</title>
      </Helmet>

      <>
        <h2 className="text-2xl font-semibold flex justify-center">{"購物車內容"}</h2>
        <span className="text-s text-neutral-500 dark:text-neutral-400 font-normal flex justify-center">
          購物滿港幣$150免運費
        </span>

        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          {displayCart.map((item) => {
            let formattedTitle = (item.title || "").replaceAll(";", "");
            return (
              <>
                <div
                  className="nc-NcInputNumber w-full listingSection__wrap"
                  data-nc-id="NcInputNumber"
                  key={item.id}
                  style={{ padding: "12px" }}
                  // style={{ boxSizing: 'border-box', padding: '12px', border: '1px solid gray', borderRadius: '6px' }}
                >
                  <span className="font-medium text-neutral-800 dark:text-neutral-200">{formattedTitle}</span>
                  {/* 商品的优惠 */}
                  {/* <div className="text-s text-neutral-500 dark:text-neutral-400 font-normal"> 每個產品的小計價格已經為您計算了最優惠的價格 </div> */}
                  {/* <div className="text-s dark:text-neutral-400 font-normal"> 以下是商品的優惠規則↓↓ </div> */}
                  {/* <DiscountRulesRender discountRules={item.discountRules} /> */}
                  {item.selectedSkuList.map((sku: any) => {
                    return (
                      <div className="flex" style={{ margin: "10px 0" }} key={sku.id}>
                        <div style={{ width: "80%", marginLeft: "12px" }}>
                          <div className="text-s text-neutral-500 dark:text-neutral-400 font-normal">
                            {sku.name} / 原價：${sku.salePrice}
                          </div>
                          <DiscountRulesRender discountRules={sku.discountRules} />
                        </div>
                        <div className={`flex items-center justify-between`} style={{ width: "11rem" }}>
                          <div className={`nc-NcInputNumber flex items-center justify-between w-28`}>
                            <button
                              className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                              type="button"
                              onClick={() => decreaseAmount(sku.id, item.id)}
                            >
                              <MinusIcon className="w-4 h-4" />
                            </button>

                            <span>{sku.count || 0}</span>

                            <button
                              className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                              type="button"
                              onClick={() => increaseAmount(sku.id, item.id)}
                            >
                              <PlusIcon className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <SubtotalRender apiBreakdown={apiBreakdown} id={item.id} />
                </div>
              </>
            );
          })}

          <div>
            <Label>{"優惠碼 (可選填)"}</Label>
            <Input
              name="discountCode"
              value={inputDiscountCode}
              onChange={handleDiscount}
              placeholder={"請輸入優惠碼"}
            />
            {cantUseCouponMsg !== "" ? (
              <div style={{ marginTop: "4px" }}>
                <span className="w-full font-medium" style={{ color: "rgb(239 68 68)" }}>
                  {cantUseCouponMsg} - 請刪除優惠券代碼
                </span>
              </div>
            ) : null}
          </div>

          <div>
            <Label>{"*必填 - 聯絡人電話 (請提供可接受短訊之電話號碼)"}</Label>
            <Input name="phone" value={phone} onChange={handlePhone} placeholder={"請輸入您的聯絡人電話"} />
            {phoneErrMsg ? (
              <div style={{ marginTop: "4px" }}>
                <span className="w-full font-medium" style={{ color: "rgb(239 68 68)" }}>
                  您的電話號碼只能包含 8 個數字
                </span>
              </div>
            ) : null}
          </div>

          {/* <span
						className="text-s text-neutral-500 dark:text-neutral-400 font-normal flex"
					>
						每個產品的小計價格已經為您計算了最優惠的價格
					</span> */}

          <div className="flex justify-between">
            {freeDelivery ? (
              <>
                <span className="font-medium text-neutral-800 dark:text-neutral-200">運費: HKD $0</span>

                <span className="font-medium text-neutral-800 dark:text-neutral-200">
                  {`${"小計:"} HKD $${subtotal}`}
                </span>
              </>
            ) : (
              <>
                <span className="font-medium text-neutral-800 dark:text-neutral-200">
                  {`運費: HKD $${shippingFee}`}
                </span>

                <span className="font-medium text-neutral-800 dark:text-neutral-200">
                  {`${"小計:"} HKD $${subtotal}`}
                </span>
              </>
            )}
          </div>

          {discountByAmount ? (
            <div className="flex justify-between">
              <div></div>
              <span className="font-medium text-neutral-800 dark:text-neutral-200">
                {`${"優惠券折扣:"} HKD -$${displayDiscount}`}
              </span>
            </div>
          ) : null}

          {/* {discountByPercentage ? (
            <div className="flex justify-between">
              <div></div>
              <span className="font-medium text-neutral-800 dark:text-neutral-200">
                {`${"優惠券折扣:"} -${displayDiscount}%`}
              </span>
            </div>
          ) : null} */}

          <div className="flex justify-between">
            <div></div>
            <h3 className="text-2xl font-semibold">{`${"總金額:"} HKD $ ${total.toFixed(2)}`}</h3>
          </div>

          {/* <div>
						<span
							className="font-medium text-neutral-800 dark:text-neutral-200"
						>
							明細如下
						</span>
						<DiscountDetailRender apiBreakdown={apiBreakdown} />
					</div> */}
        </div>
      </>

      {renderPopUp()}
    </CommonLayout>
  );
};

export default Cart;
