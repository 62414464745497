import React, { FC, useContext } from "react";
// import StayCard from "components/StayCard/StayCard";
import ProductCard from "./ProductCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
// import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import { GoogleContext } from "context/google";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 4);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {

  const { products } = useContext(GoogleContext);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
      style={{width: '100%'}}
    >
      <Heading2 />

      {/* <div 
        className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        {data.map((stay) => (
          <StayCard key={stay.id} data={stay} />
        ))}
      </div> */}

      <div 
        className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        {products.map((item, index) => (
          <ProductCard key={`product-card-${item.id}`} uniqueKey={item.id} data={item} />
        ))}
      </div>

      {/* <div 
        className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        {allSeafood.map((item) => (
          <ProductCard key={item.id} data={item} />
        ))}
      </div> */}
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;