import React, { FC, Fragment } from "react";
// import { Tab } from "@headlessui/react";
// import { PencilAltIcon } from "@heroicons/react/outline";
// import visaPng from "images/vis.png";
// import mastercardPng from "images/mastercard.svg";
// import Input from "shared/Input/Input";
// import Label from "components/Label/Label";
// import Textarea from "shared/Textarea/Textarea";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import NcImage from "shared/NcImage/NcImage";
// import StartRating from "components/StartRating/StartRating";
// import NcModal from "shared/NcModal/NcModal";
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from "@stripe/react-stripe-js";
// import { publishableKey } from './constant';
import CustomerInfoPage from "containers/CustomerInfoPage/CustomerInfoPage";


export interface CheckOutPageProps {
  className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  // const stripePromise = loadStripe(publishableKey.test);
  return (
    <>
      {/* <Elements stripe={stripePromise}> */}
        <CustomerInfoPage />
      {/* </Elements> */}
    </>
  );
};

export default CheckOutPage;
