import React, { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "components/Spinner/Spinner";
import OneButtonCommonLayout from "containers/PageAddListing1/OneButtonCommonLayout";
import { AuthContext } from "context/authenticate";
import { CartContext } from "context/cart";
import { Helmet } from "react-helmet";

//API
import Axios from "axios";
import { url } from "../../utils/constant";

export interface PaymentConfirmProps {
  className?: string;
}

const PaymentConfirmPage: FC<PaymentConfirmProps> = ({ className = "" }) => {
  //Status === 0 | Order is pending for deposit
  //Status === 1 | Order is pending for success
  //Status === 2 | Order is pending for fail

  //id is merchant reference

  const { id } = useParams() as any;

  const { companyName } = useContext(AuthContext);
  const { clearCart } = useContext(CartContext);

  const [loading, setLoading] = useState(true);

  const [successful, setSuccessful] = useState(false);
  const [unSuccessful, setUnsuccessful] = useState(false);

  const [submissionId, setSubmissionId] = useState("");

  const paymentAsiaReturn = async (merchantReference: any) => {
    try {
      let data = {
        merchantReference,
        paymentMethod: "Asia",
      };

      const result = await Axios.post(`${url}/rapidcart/web/receipt/thirdPartyAfterPay`, data);
      console.log("PA Return:", result);

      if (result.data.data === null && result.data.code === 500) {
        setLoading(false);
        setUnsuccessful(true);
        return;
      }

      let customerData = result.data.data.orderInfo;
      let resStatus = result.data.data.outOrderInfo[0].status;
      let transactionId = result.data.data.orderInfo.transactionId;

      setSubmissionId(transactionId);

      let response = {
        status: resStatus,
        customer_name: customerData.name,
        customer_phone: customerData.phone,
        customer_email: customerData.email,
        customer_address: customerData.address,
        skuList: customerData.skuList,
        deliveryFee: customerData.deliveryFee,
        subtotal: customerData.originTransactionTotal,
        total: customerData.transactionTotal,
        promotionAmount: customerData.coupon,
        transactionId,
        network: customerData.payNetwork,
      };

      if (customerData.couponCode === "") {
        response.promotionAmount = "不適用";
      } else {
        response.promotionAmount = customerData.couponCode;
      }

      return response;
    } catch (err) {
      console.log("PA Return Error:", err);
    }
  };

  // const emailReceipt = async (
  //   response: any,
  // ) => {

  //   let productOrderArray: any = [];

  //   try {
  //     let data = {
  //       customerName: response.customer_name,
  //       customerEmail: response.customer_email,
  //       customerPhone: response.customer_phone,
  //       customerAddress: response.customer_address,
  //       referenceNo: response.transactionId,
  //       productOrder: productOrderArray,
  //       deliveryFee: response.deliveryFee,
  //       subtotal: response.subtotal,
  //       total: response.total,
  //       promotionAmount: response.promotionAmount,
  //       paymentMethod: response.network,
  //     };

  //     let products = response.skuList;

  //     products.forEach((item: any) => {
  //       data.productOrder.push({
  //         title: item.skuName,
  //         remarks: "",
  //         amount: item.buyCount,
  //         price: item.skuSalePrice,
  //         subTotal: item.buyCount * item.skuSalePrice,
  //       });
  //     });

  //     const result = await Axios.post(`${url}/rapidcart/web/utils/emailReceipt`, data);
  //     // console.log('Email Result:', result);

  //     return result;

  //   } catch (err) {
  //     console.log('Email Receipt Error:', err);
  //   }
  // };

  const executeReturn = async () => {
    await paymentAsiaReturn(id);
    const response = await paymentAsiaReturn(id);

    // console.log("response:", response);

    if (response?.status === "1") {
      // await emailReceipt(response);

      setTimeout(() => {
        setLoading(false);
        setSuccessful(true);
        clearCart();
      }, 300);
    } else if (response?.status === "2") {
      setLoading(false);
      setUnsuccessful(true);
    }
  };

  useEffect(() => {
    executeReturn();
  }, []);

  return (
    <main className="container mt-11 mb-24 lg:mb-32 flex justify-center lg:flex-row">
      <Helmet>
        <title>{companyName}</title>
      </Helmet>

      {loading === true && <Spinner className="pt-24 pb-24 lg:py-32" />}

      {loading === false && successful === true && (
        <OneButtonCommonLayout backtHref="/">
          <h2 className="text-3xl lg:text-4xl font-semibold">{"感謝你購買乳酪火車產品"}</h2>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <h3 className="text-2xl font-semibold">
            {`你已下單成功，你的訂單編號為：${submissionId}，我們將於3個工作天內為你安排送貨。`}
          </h3>
        </OneButtonCommonLayout>
      )}

      {loading === false && unSuccessful === true && (
        <OneButtonCommonLayout backtHref="/">
          <h2 className="text-3xl lg:text-4xl font-semibold">{"好像有錯誤"}</h2>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <h3 className="text-2xl font-semibold">{"您的提交或付款失敗。請再試一次。如果問題仍然存在，請聯繫我們。"}</h3>
        </OneButtonCommonLayout>
      )}
    </main>
  );
};

export default PaymentConfirmPage;
