import React, { useContext, useEffect } from "react";
import MyRouter from "routers/index";

//Google Analytics 4
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

//API
import Axios from "axios";
import { url } from "./utils/constant";

//Context
import { AuthContext } from "context/authenticate";

const trackingID = "G-TTJJC42K33";

const tagManagerArgs = {
  gtmId: "GTM-5QXNKWV",
  dataLayerName: "CartDataLayer",
  events: {
    add_to_cart: "add_to_cart",
  },
};

if (process.env.REACT_APP_STAGE === "production") {
  ReactGA.initialize(trackingID);
  TagManager.initialize(tagManagerArgs);
  ReactGA.send("pageview");
}

function App() {
  const { setCompanyName, setCompanyEmail, setCompanyPhone, setCompanyLogo, setHomePageUrl, setShowNavItems } =
    useContext(AuthContext);

  const getWebsiteDetail = async () => {
    try {
      const result = await Axios.post(`${url}/rapidcart/web/website/detail`);

      let data = result.data.data;
      // console.log("Get Website Result:", data);

      if (data !== null) {
        setCompanyName(data.name);
        setCompanyEmail(data.email);
        setCompanyPhone(data.phone);
        setCompanyLogo(data.logo);
        setShowNavItems(data.showNavItems);

        if (data.homePageUrl !== null || data.homePageUrl !== undefined) {
          setHomePageUrl(data.homePageUrl);
        }
      }
    } catch (err) {
      console.log("Get Website Error:", err);
    }
  };

  useEffect(() => {
    getWebsiteDetail();
  }, []);

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
