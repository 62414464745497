import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
// import { AuthContext } from "context/authenticate";

function Navigation() {
  // const { homePageUrl } = useContext(AuthContext);

  // if (homePageUrl !== "") {
  //   NAVIGATION_DEMO.forEach((item: any) => {
  //     item.href = homePageUrl;
  //   });
  // }

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {NAVIGATION_DEMO.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
