import React, { FC, useEffect, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
import noImage from "../../images/404.png";

export interface ProductCardProps {
  className?: string;
  ratioClass?: string;
  uniqueKey: string;
  data?: any;
  size?: "default" | "small";
}

const ProductCard: FC<ProductCardProps> = ({
  size = "default",
  className = "",
  data = {},
  ratioClass,
  uniqueKey = "",
}) => {
  const { title, id, pictureUrls, discountFlag, skuList } = data;

  const [soldOut, setSoldOut] = useState(false);

  const checkSoldOut = () => {
    const allItemsSoldOut = skuList.every((item: any) => item.stock === 0);

    if (allItemsSoldOut) {
      setSoldOut(true);
    }
  };

  // const [selectedIndex, setSelectedIndex] = useState(index);

  let price = skuList[0].salePrice;

  const PHOTOS: string[] = [];

  if (pictureUrls.length > 0) {
    PHOTOS.push(pictureUrls[0].url);
  } else {
    PHOTOS.push(noImage);
  }

  // let splitTitle = title.split(';');

  // const selectedIndex = index;

  // const handleIndexChange = () => {
  //   console.log('clicked on index:', index);
  //   let strIndex = index.toString();
  // };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider ratioClass={ratioClass} galleryImgs={PHOTOS} />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
        {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          {/* <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {listingCategory.name} · {bedrooms} beds
          </span> */}
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2 className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"}`}>
              {/* {
                splitTitle.map((sentence: string, index: number) => {
                  return <>
                    <span className="line-clamp-3" key={`${sentence}-${index}`}>{sentence}</span>
                  </>
                })
              } */}
              <span className="line-clamp-3 text-[18px]">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {/* {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )} */}
            {/* <span className="">{address}</span> */}
          </div>
        </div>
        <div className="w-100 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div
          className="flex justify-between items-center"
          // style={{justifyContent: 'end'}}
        >
          {soldOut ? (
            <span
              className="text-base font-semibold"
              style={{
                color: "white",
                backgroundColor: "red",
                borderRadius: "8px",
                padding: "3px",
              }}
            >
              售罄
            </span>
          ) : (
            <>
              {discountFlag ? (
                <span
                  className="text-base font-semibold"
                  style={{
                    color: "white",
                    backgroundColor: "orange",
                    borderRadius: "8px",
                    padding: "3px",
                  }}
                >
                  優惠
                </span>
              ) : (
                <div></div>
              )}
            </>
          )}

          <span className="text-base font-semibold">
            {`HKD $${price}`}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">{"per unit"}</span>
            )}
          </span>
          {/* {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )} */}
        </div>
      </div>
    );
  };

  useEffect(() => {
    checkSoldOut();
  }, []);

  return (
    <div
      className={`nc-ProductCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id={uniqueKey}
      // onClick={handleIndexChange}
    >
      <Link
        to={{
          pathname: `/product-detail/${id}`,
        }}
      >
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default ProductCard;
