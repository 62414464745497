import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from "react";
// import { API } from "aws-amplify";
import { AuthContext } from "context/authenticate";
import { CartContext } from "context/cart";
import { Tab } from "@headlessui/react";
// import visaPng from "images/vis.png";
// import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import OneButtonCommonLayout from "containers/PageAddListing1/OneButtonCommonLayout";
// import moment from "moment";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import "./CustomerInfoPage.scss";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Spinner from "components/Spinner/Spinner";
// import { dataURLtoFile } from './dataURLtoFile';
// import getAndPost from './uploadRequest';
// import { prodBucketName, getSecretUrl, imageBucketUrl, emailRegex } from './constant';
import { emailRegex } from "./constant";
import fps from "images/fps.png";
import payme from "images/payme_logo.png";
import paymeHsbc from "images/paymeHSBC.png";
import aliPay from "images/aliPay.png";
import weChat from "images/weChat.png";
// import thumbnail from "images/upload3x.png";
import master from "images/masterLogo.png";
import visa from "images/visaLogo.png";
import Select from "shared/Select/Select";
// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Helmet } from "react-helmet";
import noImage from "images/404.png";

import QrCodeGen from "./QrCodeGen/QrCodeGen";
//API
import Axios from "axios";
import { url } from "../../utils/constant";

//Socket IO
import io from "socket.io-client";

export interface CustomerInfoProps {
  className?: string;
}

const BuyChInfoList = [
  { label: "請選擇", value: "" },
  { label: "Facebook / Instagram", value: 1 },
  { label: "展覽會", value: 2 },
  { label: "舊有客戶", value: 7 },
  { label: "HKTVMALL", value: 3 },
  { label: "網上搜尋", value: 4 },
  { label: "朋友介紹", value: 5 },
  { label: "其他〈請註明〉", value: 6 },
];

// const CARD_ELEMENT_OPTIONS = {
//   style: {
//     base: {
//       color: "#32325d",
//       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
//       fontSmoothing: "antialiased",
//       fontSize: "16px",
//       "::placeholder": {
//         color: "#aab7c4"
//       }
//     },
//     invalid: {
//       color: "#fa755a",
//       iconColor: "#fa755a"
//     },
//     hidePostalCode: true
//   }
// };

function CheckoutSummaryRender(props: any) {
  const { apiBreakdown, cart } = props;
  if (!Array.isArray(apiBreakdown) || !apiBreakdown.length) {
    return null;
  }
  return (
    <>
      {apiBreakdown.map((item: any, index: number) => {
        return (
          // <div
          //   className="text-s text-neutral-500 dark:text-neutral-400 font-normal listingSection__wrap"
          //   key={`${item.inventoryName}-${item.inventoryId}`}
          //   style={{ margin: '12px 0', padding: '12px' }}
          //   // style={{ margin: '12px 0', boxSizing: 'border-box', padding: '12px', border: '1px solid #9195a3', borderRadius: '6px' }}
          // >
          //   商品：{item.inventoryName}, 总价：{item.subTotal} {item.useInventoryDiscount ? `，使用了商品折扣` : ''}
          //   {
          //     item.skuPriceList.map((sku: any) => {
          //       return (
          //         <div style={{ marginLeft: '12px' }}>
          //           購買了 【{sku.name}】【{sku.count}】件，原價：${sku.totalOriginPrice} 折扣價：${sku.discountPrice}
          //         </div>
          //       )
          //     })
          //   }
          // </div>

          <div
            className="flex justify-between text-s text-neutral-500 dark:text-neutral-400 font-normal"
            key={`${item.inventoryName}-${item.inventoryId}`}
            style={{ margin: "12px 0", padding: "12px" }}
          >
            <div className="flex">
              <div className="" style={{ marginRight: "12px" }}>
                {cart.length > 0 ? (
                  <>
                    {cart[index].pictureUrls.length > 0 ? (
                      <img
                        src={cart[index].pictureUrls[0].url || ""}
                        style={{
                          width: "100px",
                          height: "auto",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={noImage}
                        style={{
                          width: "100px",
                          height: "auto",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                        alt=""
                      />
                    )}
                  </>
                ) : null}
              </div>

              <div className="flex flex-col">
                <div>{item.inventoryName}</div>
                {item.skuPriceList.map((sku: any) => {
                  // console.log('sku:', sku)
                  return <>{item.inventoryName !== sku.name && <div style={{ marginLeft: "12px" }}>{sku.name}</div>}</>;
                })}
              </div>
            </div>

            <div className="flex items-center">{`$${item.subTotal}`}</div>
          </div>
        );
      })}
    </>
  );
}

const CustomerInfoPage: FC<CustomerInfoProps> = ({ className = "" }) => {
  const returnUrl = `${window.location.origin}/payment-result/`;
  const payMeReturnUrl = `${window.location.origin}/payme-result`;

  const { companyName } = useContext(AuthContext);
  const {
    cart,
    // clearCart,
    total,
    subtotal,
    shippingFee,
    discountCodes,
    inputDiscountCode,
    apiBreakdown,
    discountByAmount,
    discountByPercentage,
    displayDiscount,
    phone,
  } = useContext(CartContext);

  const [loading, setLoading] = useState(false);
  const [successMesage, setSuccessMessage] = useState<any>(undefined);
  const [isOpenModalPopUp, setIsOpenModalPopUp] = useState(false);

  const [formFilled, setFormFilled] = useState(true);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);

  const [soldOut, setSoldOut] = useState(false);
  const [soldOutItem, setSoldOutItem] = useState("");

  const [insufficientStock, setInsufficientStock] = useState(false);
  const [insufficientItem, setInsufficientItem] = useState({
    title: "",
    quantity: "",
  });

  const [values, setValues] = useState({
    customer_first_name: "",
    customer_last_name: "",
    customer_phone: "",
    customer_email: "",
    customer_address: "",
    customer_ip: "",
    merchant_reference: "",
    network: "",
    subject: "",
    sign: "",
    methodOfPayment: "PayMe",
    region: "區域",
    district: "",
    chInfo: "",
    chInfoText: "",
  });

  // const [merchantRef, setMerchantRef] = useState('');

  const [submissionId, setSubmissionId] = useState("");
  // const [error, setError] = useState<any>(null);

  const [dynamicForm, setDynamicForm] = useState(true);

  const [qrLink, setQrLink] = useState("");
  const [showQrCode, setShowQrCode] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [payMeServerError, setPayMeServerError] = useState(false);
  const [payMeExpired, setPayMeExpired] = useState(false);
  const [payMeNoStatus, setPayMeNoStatus] = useState(false);
  const [mobileDevice, setMobileDevice] = useState(false);

  const mobileCheck = () => {
    if (/ipad|tablet/i.test(navigator.userAgent)) {
      setMobileDevice(true);
      // return console.log("it's an Ipad or Tablet");
    } else if (/mobile/i.test(navigator.userAgent)) {
      setMobileDevice(true);
      // return console.log("it's a Mobile");
    } else {
      setMobileDevice(false);
      // return console.log("it's a Desktop");
    }
  };

  let options = [
    // { value: "轉數快" },
    { value: "PayMe" },
    { value: "信用卡" },
    { value: "Wechat" },
    { value: "Alipay" },
    { value: "Fps" },
  ];

  let regions = [{ value: "區域" }, { value: "香港島" }, { value: "九龍區" }, { value: "新界區" }];

  let defaultDistrict = [{ value: "分區" }];

  let districtHkI = [{ value: "分區" }, { value: "中西區" }, { value: "灣仔區" }, { value: "東區" }, { value: "南區" }];

  let districtKl = [
    { value: "分區" },
    { value: "九龍城區" },
    { value: "觀塘區" },
    { value: "深水埗區" },
    { value: "黃大仙區" },
    { value: "油尖旺區" },
  ];

  let districtNt = [
    { value: "分區" },
    { value: "葵青區" },
    { value: "荃灣區" },
    { value: "離島區" },
    { value: "北區" },
    { value: "西貢區" },
    { value: "沙田區" },
    { value: "大埔區" },
    { value: "屯門區" },
    { value: "元朗區" },
  ];

  const closeModalPopUp = () => {
    setIsOpenModalPopUp(false);
  };

  const openModalPopUp = () => {
    setIsOpenModalPopUp(true);
  };

  const callBackRedirect = (url: any) => {
    window.location.href = `${url}`;
  };

  //Find the correct product to update
  const findTheCorrectProducts = (allProducts: any, cartItems: any) => {
    let arr = [];

    for (let productRow = 0; productRow < allProducts.length; productRow++) {
      const eachProduct = allProducts[productRow];
      const eachProductSkuList = allProducts[productRow].skuList;

      for (let cartRow = 0; cartRow < cartItems.length; cartRow++) {
        const eachCartItem = cartItems[cartRow];
        const eachCartItemSelected = cartItems[cartRow].selectedSkuList;

        if (eachProduct.id === eachCartItem.id) {
          for (let i = 0; i < eachProductSkuList; i++) {
            const matchInventoryItem = eachProductSkuList[i];
            const matchCartItem = eachCartItemSelected[i];

            //match product inventory skulist skuId with cartItem selected skuList skuId
            if (matchInventoryItem.id === matchCartItem.id) {
              // const currentQuantity = eachProduct.quantity;
              // const parseCurrent = parseInt(currentQuantity);
              // const deductQuantity = eachCartItem.amount;

              const currentQty = matchInventoryItem.stock;
              const deductQty = matchCartItem.count;

              setSoldOut(false);
              setSoldOutItem("");
              setInsufficientStock(false);
              setInsufficientItem((prevState) => ({
                ...prevState,
                title: "",
                quantity: "",
              }));

              // if (parseCurrent > deductQuantity || parseCurrent === deductQuantity) {
              if (currentQty > deductQty || currentQty === deductQty) {
                // console.log('current qty is equal or greater than deduct qty')

                // arr.push({
                //   "id": eachCartItem.id,
                //   "deductQuantity": deductQuantity
                // });
                arr.push({
                  id: eachCartItem.id,
                  deductQuantity: deductQty,
                });
              } else {
                // console.log('current qty is not equal or greater than deduct qty')

                // if (parseCurrent === 0) {
                if (currentQty === 0) {
                  setSoldOut(true);
                  // setSoldOutItem(eachProduct.title);
                  setSoldOutItem(matchInventoryItem.name);
                  setLoading(false);
                  openModalPopUp();
                  // console.log(`We are sold out of ${eachProduct.title}`)
                  return [];
                } else {
                  setInsufficientStock(true);
                  // setInsufficientItem((insufficientItem) => ({
                  //   ...insufficientItem,
                  //   title: eachProduct.title,
                  //   quantity: currentQuantity,
                  // }));
                  setInsufficientItem((insufficientItem) => ({
                    ...insufficientItem,
                    title: matchInventoryItem.name,
                    quantity: currentQty,
                  }));
                  setLoading(false);
                  openModalPopUp();
                  // console.log(`We only have ${currentQuantity} left for ${eachProduct.title}`)
                  return [];
                }
              }
            }
          }
        }
      }
    }
    return arr;
  };

  const fetchAllProductsBeforeSubmit = async () => {
    try {
      // const result = await API.get('googleGetAllProducts', '/getAllProducts', {});
      const result = await Axios.post(`${url}/rapidcart/web/inventory/queryAll`, { pageSize: 100000 });
      const currentProduct = result.data.data.lists;

      if (currentProduct.length > 0) {
        currentProduct.map((item: any) => {
          return (item.id = item.id.toString());
        });
      }

      const res = findTheCorrectProducts(currentProduct, cart);

      return res;
    } catch (err) {
      console.log("fetchAllGoogleError:", err);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePaymentChange = (e: any) => {
    const { value } = e.target;

    if (value === "Wechat" || value === "Alipay" || value === "Fps" || value === "信用卡") {
      setDynamicForm(false);

      setValues((values) => ({
        ...values,
        methodOfPayment: value,
      }));
    } else {
      setDynamicForm(true);

      setValues((values) => ({
        ...values,
        methodOfPayment: value,
      }));
    }
  };

  const handleRegionChange = (e: any) => {
    const { value } = e.target;

    setValues((values) => ({
      ...values,
      region: value,
    }));
  };

  const handleDistrictChange = (e: any) => {
    const { value } = e.target;

    setValues((values) => ({
      ...values,
      district: value,
    }));
  };

  const handleChInfoChange = (e: any) => {
    const { value } = e.target;
    setValues((values) => ({
      ...values,
      chInfo: value,
      chInfoText: `${value}` === "6" ? "" : BuyChInfoList.find((n) => n.value == value)?.label || "",
    }));
  };

  //Payment Asia - YogurTrain only
  const getPaymentAsiaSign = async (cart: any, productAmount: any) => {
    try {
      let skuListArray: any = [];

      let data = {
        skuList: skuListArray,
        customerFirstName: values.customer_first_name,
        customerLastName: values.customer_last_name,
        customerPhone: values.customer_phone,
        customerEmail: values.customer_email,
        customerAddress: `${values.customer_address}-${values.region}-${values.district}`,
        customerPostalCode: "999077",
        paymentMethod: "Asia",
        network: values.methodOfPayment,
        remarks: "",
        returnUrl: returnUrl,
        couponCode: inputDiscountCode,
        chInfo: values.chInfo,
        chInfoText: values.chInfoText,
      };

      for (let i = 0; i < cart.length; i++) {
        const skuList = cart[i].selectedSkuList;

        for (let j = 0; j < skuList.length; j++) {
          data.skuList.push({
            inventoryId: cart[i].id,
            skuId: skuList[j].id,
            count: skuList[j].count,
          });
        }
      }

      const result = await Axios.post(`${url}/rapidcart/web/receipt/thirdPartyPay`, data);

      // console.log('Get Signature Res:', result);

      let resData = result.data.data;

      // setMerchantRef(resData.merchantReference);
      let html = resData.formHtml;
      return html;
    } catch (err) {
      console.log("Get Signature Error:", err);
    }
  };

  const paymePayment = async (cart: any) => {
    try {
      let skuListArray: any = [];

      let data = {
        returnUrl: payMeReturnUrl,
        failCallbackUrl: payMeReturnUrl,
        customerFirstName: values.customer_first_name,
        customerLastName: values.customer_last_name,
        customerAddress: `${values.customer_address}-${values.region}-${values.district}`,
        customerPhone: values.customer_phone,
        customerEmail: values.customer_email,
        paymentMethod: "PayMe",
        skuList: skuListArray,
        couponCode: inputDiscountCode,
        chInfo: values.chInfo,
        chInfoText: values.chInfoText,
      };

      for (let i = 0; i < cart.length; i++) {
        const skuList = cart[i].selectedSkuList;

        for (let j = 0; j < skuList.length; j++) {
          data.skuList.push({
            inventoryId: cart[i].id,
            skuId: skuList[j].id,
            count: skuList[j].count,
          });
        }
      }

      // const result = await Axios.post(`${url}/rapidcart/web/utils/paymePayment`, data);
      const result = await Axios.post(`${url}/rapidcart/web/receipt/thirdPartyPay`, data);

      console.log("PayMe Payment Result:", result.data);

      let response = {
        code: 0,
        paymentRequestId: "",
        webLink: "",
      };

      if (result.data.code === 200) {
        response.code = result.data.code;
        response.paymentRequestId = result.data.data.paymentRequestId;
        response.webLink = result.data.data.webLink;

        // let customOrderId = result.data.data.appSuccessCallback;
        // let parsedCustom = customOrderId.substring(customOrderId.lastIndexOf('/') + 1);

        setSubmissionId(result.data.data.paymentRequestId);
      } else {
        response.code = result.data.code;
      }

      return response;
    } catch (err) {
      console.log("PayMe Payment Error:", err);
    }
  };

  const paymePaymentQuery = async (merchantReference: any) => {
    try {
      let data = {
        merchantReference,
      };

      const result = await Axios.post(`${url}/rapidcart/web/utils/paymePaymentQuery`, data);

      // console.log('PayMe Payment Query Result:', result.data.data);

      let response = result.data;

      return response;
    } catch (err) {
      console.log("PayMe Payment Query Error:", err);
    }
  };

  const handleRedirectView = async (e: any) => {
    e.preventDefault();
    //Check Form First
    if (
      values.customer_last_name === "" ||
      values.customer_first_name === "" ||
      values.customer_phone === "" ||
      values.customer_email === "" ||
      values.customer_address === "" ||
      values.methodOfPayment === "" ||
      values.region === "" ||
      values.region === "區域" ||
      values.district === "" ||
      values.district === "分區" ||
      values.chInfo === "" ||
      (values.chInfo === "6" && !values.chInfoText)
    ) {
      setFormFilled(false);
      openModalPopUp();
      return;
    } else if (!!values.customer_email.match(emailRegex) === false) {
      setInvalidEmail(true);
      openModalPopUp();
      return;
    } else if (values.customer_phone.length !== 8) {
      setInvalidPhone(true);
      openModalPopUp();
      return;
    } else {
      setFormFilled(true);
      setInvalidEmail(false);
      setInvalidPhone(false);
    }

    const html = await getPaymentAsiaSign(cart, total);

    // e.target.submit();
    document.body.innerHTML += `${html}`;
    //@ts-ignore
    document.forms["payment"].submit();
  };

  const generateQrCode = async (e: any) => {
    e.preventDefault();

    if (payMeServerError) {
      setPayMeServerError(false);
    }
    //Check Form First
    if (
      values.customer_last_name === "" ||
      values.customer_first_name === "" ||
      values.customer_phone === "" ||
      values.customer_email === "" ||
      values.customer_address === "" ||
      values.methodOfPayment === "" ||
      values.region === "" ||
      values.region === "區域" ||
      values.district === "" ||
      values.district === "分區" ||
      !values.chInfo ||
      !values.chInfoText
    ) {
      setFormFilled(false);
      openModalPopUp();
      return;
    } else if (!!values.customer_email.match(emailRegex) === false) {
      setInvalidEmail(true);
      openModalPopUp();
      return;
    } else if (values.customer_phone.length !== 8) {
      setInvalidPhone(true);
      openModalPopUp();
      return;
    } else {
      setFormFilled(true);
      setInvalidEmail(false);
      setInvalidPhone(false);
    }

    const response = await paymePayment(cart);
    console.log("Response PayMe:", response);

    if (mobileDevice) {
      if (response?.code === 200) {
        console.log("Redirect to weblink");
        window.location.replace(response.webLink);
      } else {
        console.log("Please try again later or use a different method of payment.");
        setPayMeServerError(true);
        openModalPopUp();
      }
    } else {
      if (response?.code === 200) {
        setQrLink(response.webLink);
        setPaymentId(response.paymentRequestId);
        setShowQrCode(true);
      } else {
        console.log("Please try again later or use a different method of payment.");
        setPayMeServerError(true);
        openModalPopUp();
      }
    }
  };

  const handlePayMeSuccess = useCallback(async () => {
    const res = await fetchAllProductsBeforeSubmit();

    if (res !== undefined && res.length > 0) {
      console.log("Inventory Checked:", res);
      let promotionAmount: any;
      // let pictureProof: any = [];

      //send cart to backend to deduct inventory and create sales receipt
      // await createReceipt(cart, pictureProof);

      if (discountCodes.length > 0 && inputDiscountCode !== "") {
        discountCodes.forEach((item: any) => {
          if (inputDiscountCode === item.discountCode) {
            if (item.discountAmount) {
              promotionAmount = `-$${item.discountAmount}`;
            } else {
              promotionAmount = `-${item.discountPercentage}off`;
            }
          }
        });
      } else {
        promotionAmount = "不適用";
      }

      // await emailReceipt(submissionId, cart, shippingFee, subtotal, total, promotionAmount);
      // console.log('email sent');
      // setTimeout(() => {
      //   setLoading(false);
      //   setSuccessMessage(true);
      //   clearCart();
      // }, 300);
    } else {
      setTimeout(() => {
        setLoading(false);
        setSuccessMessage(undefined);
      }, 300);
    }
  }, []);

  useEffect(() => {
    mobileCheck();
  }, []);

  useEffect(() => {
    const localFormData = window.localStorage.getItem("FORM-VALUES");

    if (localFormData !== null) {
      setValues(JSON.parse(localFormData) || {});
      let obj = JSON.parse(localFormData);
      if (obj.methodOfPayment === "PayMe") {
        setDynamicForm(true);
      } else {
        setDynamicForm(false);
      }
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("FORM-VALUES", JSON.stringify(values));
  }, [values]);

  useEffect(() => {
    if (phone !== "") {
      setValues((prevState) => ({
        ...prevState,
        customer_phone: phone,
      }));
    }
  }, [phone]);

  useEffect(() => {
    if (!mobileDevice && paymentId !== "") {
      setPayMeExpired(false);
      setPayMeNoStatus(false);

      // const socket = io("ws://161.117.250.103:8000", { transports: ["websocket"] });
      const socket = io(url, { transports: ["websocket"] });

      let recursionTimer: any = undefined;
      let expired = false;

      // execute in 10 mins
      let timerTen = setTimeout(() => {
        setPayMeExpired(true);
        expired = true;
      }, 600000);

      // execute in 1 min
      let timerOne = setTimeout(() => {
        console.log("No socket response after 1 min, call this api");

        // **BEGIN**
        // New code from Kelvin
        const recursion = () => {
          console.log("RUN RECURSION FN");
          const queryPayment = paymePaymentQuery(paymentId);

          queryPayment.then((res) => {
            console.log(res);
            if (res.code === 500) {
              console.log("No Payment Status show error popup");
              setPayMeNoStatus(true);
              openModalPopUp();
            } else {
              // if payment success and no webhook callback
              console.log("No socket response but payment success");
              if (res?.data?.transactions?.[0]?.transactionId && res?.data?.statusDescription === "Payment Success") {
                console.log("SUCCESS PAYMENT AND END RECURSION");
                setLoading(true);
                clearTimeout(timerTen);
                handlePayMeSuccess();
                callBackRedirect(res.data.appSuccessCallback);
              } else if (expired) {
                console.log("EXPIRE AND END RECURSION");
                // IF EXPIRE STOP EVERYTHING
                // setPayMeExpired(true);
                openModalPopUp();
                return undefined;
              } else if (res.data.statusDescription === "Request for Payment Initiated") {
                // recursion
                recursionTimer = setTimeout(() => {
                  console.log("RUN RECURSION after 10 secs");
                  recursion();
                }, 10000);
              }
            }
          });
        };
        recursion();

        // **END**
      }, 60000);

      socket.on(`PAYME_PAYMENT_STATUS_CHANGE-${paymentId}`, (data) => {
        console.log("[订单信息]", data);
        clearTimeout(timerTen);
        clearTimeout(timerOne);
        clearTimeout(recursionTimer);

        setLoading(true);

        if (data.reasonDescription) {
          setLoading(false);
          setSuccessMessage(false);
        } else {
          if (data.statusDescription === "Payment Success") {
            //Success
            // console.log('Success');
            handlePayMeSuccess();
            callBackRedirect(data.appSuccessCallback);
          }
        }
      });
    }
  }, [paymentId]);

  // const previewBox = (source: any) => {
  //   return source.map((photo: any, index: any) => {
  //     return <div
  //             key={index}
  //             className="boxContainer"
  //             >
  //               <img
  //                 className="thumbnail"
  //                 src={photo}
  //                 key={index}
  //                 alt=""
  //               />

  //               <div className="middle">
  //                 <button
  //                   className="removeButton"
  //                   onClick={(e) => {
  //                     e.stopPropagation()
  //                     e.preventDefault()
  //                     removePicture(index)
  //                   }}
  //                 >
  //                   消除
  //                 </button>
  //               </div>
  //             </div>
  //   });
  // };

  const renderMain = () => {
    return (
      <>
        {dynamicForm ? (
          <form
            className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8"
            // onSubmit={handleSubmit}
            onSubmit={generateQrCode}
          >
            <h2 className="text-3xl lg:text-4xl font-semibold">{"結算"}</h2>
            <h3 className="text-2xl font-semibold">{"請輸入你的個人資料"}</h3>
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

            <div>
              {/* <h3 className="text-2xl font-semibold">{`你的訂單總金額為 $${total}`}</h3> */}
              <h3 className="text-2xl font-semibold">{"我們接受以下的付款方式:"}</h3>
              <div className="mt-6">
                <Tab.Group>
                  <Tab.List className="flex">
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        <div className="paymentMethodWrapper">
                          <div className="paymentMethod grid grid-cols-2 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                            <img
                              className="border border-neutral-100"
                              style={{
                                objectFit: "contain",
                                maxHeight: "200px",
                                maxWidth: "200px",
                                height: "100%",
                                width: "100%",
                              }}
                              src={payme}
                              alt=""
                            />
                            <img className="border border-neutral-100" src={fps} alt="" />
                            <img className="border border-neutral-100" src={master} alt="" />
                            <img className="border border-neutral-100" src={visa} alt="" />
                            <img className="border border-neutral-100" src={weChat} alt="" />
                            <img className="border border-neutral-100" src={aliPay} alt="" />
                          </div>
                        </div>
                      )}
                    </Tab>
                  </Tab.List>
                  <h3 className="text-2xl font-semibold mt-6">{"** 請於付款備註輸入電話號碼以作識別 **"}</h3>

                  <div className="w-100 border-b border-neutral-200 my-5"></div>

                  <Tab.Panels>
                    <Tab.Panel className="space-y-5">
                      <div className="space-y-1">
                        <Label>{"聯絡人姓氏"}</Label>
                        <Input
                          type="text"
                          name="customer_last_name"
                          value={values.customer_last_name}
                          onChange={handleChange}
                          placeholder={"請輸入您的聯絡人姓氏"}
                        />
                      </div>
                      <div className="space-y-1">
                        <Label>{"聯絡人名字"}</Label>
                        <Input
                          type="text"
                          name="customer_first_name"
                          value={values.customer_first_name}
                          onChange={handleChange}
                          placeholder={"請輸入您的聯絡人名字"}
                        />
                      </div>
                      <div className="space-y-1">
                        <Label>{"郵箱"}</Label>
                        <Input
                          type="email"
                          name="customer_email"
                          value={values.customer_email}
                          onChange={handleChange}
                          placeholder={"請輸入您的郵箱"}
                        />
                      </div>
                      <div className="space-y-1">
                        <Label>{"聯絡人電話 (請提供可接受短訊之電話號碼)"}</Label>
                        <Input
                          style={{ backgroundColor: "#e6e6fa" }}
                          name="customer_phone"
                          value={values.customer_phone}
                          onChange={handleChange}
                          placeholder={"請輸入您的聯絡人電話"}
                          disabled
                        />
                      </div>

                      <div className="space-y-1">
                        <Label>{"如何得知這個產品"}</Label>
                        <div className="flex mt-1.5">
                          <Select onChange={handleChInfoChange} value={values.chInfo}>
                            {BuyChInfoList.map((item: any) => {
                              return (
                                <option value={item.value} key={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </Select>
                          {`${values.chInfo}` === "6" && (
                            <Input
                              name="chInfoText"
                              value={values.chInfoText}
                              onChange={handleChange}
                              placeholder={"請註明"}
                            />
                          )}
                        </div>
                      </div>

                      <div
                        className="border-b border-neutral-200 dark:border-neutral-700"
                        style={{ paddingTop: "8px" }}
                      ></div>

                      <div className="space-y-1">
                        <Label>{"送貨地址"}</Label>

                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Select className="mt-1.5" onChange={handleRegionChange} value={values.region}>
                            {regions.map((region: any) => {
                              return (
                                <option defaultValue={region.value} key={region.value}>
                                  {region.value}
                                </option>
                              );
                            })}
                          </Select>

                          <Select className="mt-1.5" onChange={handleDistrictChange} value={values.district}>
                            {values.region === "區域" &&
                              defaultDistrict.map((district: any) => {
                                return (
                                  <option defaultValue={district.value} key={district.value}>
                                    {district.value}
                                  </option>
                                );
                              })}

                            {values.region === "香港島" &&
                              districtHkI.map((district: any) => {
                                return (
                                  <option defaultValue={district.value} key={district.value}>
                                    {district.value}
                                  </option>
                                );
                              })}

                            {values.region === "九龍區" &&
                              districtKl.map((district: any) => {
                                return (
                                  <option defaultValue={district.value} key={district.value}>
                                    {district.value}
                                  </option>
                                );
                              })}

                            {values.region === "新界區" &&
                              districtNt.map((district: any) => {
                                return (
                                  <option defaultValue={district.value} key={district.value}>
                                    {district.value}
                                  </option>
                                );
                              })}
                          </Select>
                        </div>

                        <Input
                          name="customer_address"
                          value={values.customer_address}
                          onChange={handleChange}
                          placeholder={"請輸入您的送貨地址"}
                        />
                      </div>

                      <div
                        className="border-b border-neutral-200 dark:border-neutral-700"
                        style={{ paddingTop: "8px" }}
                      ></div>

                      <div className="space-y-1">
                        <Label>{"付款方式"}</Label>
                        <Select className="mt-1.5" value={values.methodOfPayment} onChange={handlePaymentChange}>
                          {options.map((point: any) => {
                            return (
                              <option value={point.value} key={point.value}>
                                {point.value}
                              </option>
                            );
                          })}
                        </Select>
                      </div>

                      {/* {
                            values.methodOfPayment === "信用卡"
                              ?
                                <div className="checkout-form">
                                  <div className="stripe-section">
                                    <CardElement id="stripe-element" options={CARD_ELEMENT_OPTIONS} onChange={handleStripeErrorChange} />
                                  </div>
                                  <div className="card-errors" role="alert">
                                    {error}
                                  </div>
                                </div>

                              :
                                <div className="space-y-1">
                                  <Label>{"付款證明 (請確認你的付款證明上可清楚顯示收款人姓名, 付款時間, 付款金額及備註中輸入的電話號碼)"}</Label>
                                
                                  <div className="uploadWrapper">
                                    {
                                      preview
                                        ? 
                                          <div className="preview">
                                            {previewBox(imageFiles)}
                                          </div>
                                        : 
                                          null
                                    }
              
                                    {
                                      imageAttached
                                        ?
                                          null
                                        :
                                          <div className="upload" onClick={triggerFileSelectPopup}>
                    
                                            <img src={thumbnail} alt="thumbnail" className="uploadImage"/>
                    
                                            <h3 className="text-xs">上傳您的付款證明</h3>
                    
                                            <input 
                                              type="file" 
                                              id="file"
                                              accept="image/*"
                                              ref={inputRef}
                                              onChange={selectFileHandler}
                                              style={{ display: 'none' }}
                                            />
                                          </div>
                                    }
                                  </div>
                                </div>
                          } */}
                      <div className="space-y-1">
                        {/* <Label>{"付款證明 (請確認你的付款證明上可清楚顯示收款人姓名, 付款時間, 付款金額及備註中輸入的電話號碼)"}</Label> */}

                        {mobileDevice ? (
                          <>
                            <Label>{"您已選擇以PayMe作為付款方式，並且將被重新定向至PayMe應用程式以完成付款。"}</Label>
                          </>
                        ) : (
                          <>
                            <Label>{"1. 請輸入以上所有信息"}</Label>
                            <br />
                            <Label>{"2. 確保所有信息正確無誤"}</Label>
                            <br />
                            <Label>{"3. 按確認並發送按鈕"}</Label>
                            <br />
                            <Label>{"4. 將出現一個二維碼並通過 PayMe 掃描該二維碼"}</Label>
                            <br />
                          </>
                        )}

                        {/* <div className="uploadWrapper">
                              {
                                preview
                                  ? 
                                    <div className="preview">
                                      {previewBox(imageFiles)}
                                    </div>
                                  : 
                                    null
                              }
        
                              {
                                imageAttached
                                  ?
                                    null
                                  :
                                    <div className="upload" onClick={triggerFileSelectPopup}>
              
                                      <img src={thumbnail} alt="thumbnail" className="uploadImage"/>
              
                                      <h3 className="text-xs">上傳您的付款證明</h3>
              
                                      <input 
                                        type="file" 
                                        id="file"
                                        accept="image/*"
                                        ref={inputRef}
                                        onChange={selectFileHandler}
                                        style={{ display: 'none' }}
                                      />
                                    </div>
                              }
                            </div> */}
                      </div>
                      {showQrCode ? (
                        <div className="space-y-1">
                          <img
                            style={{
                              objectFit: "contain",
                              maxHeight: "250px",
                              maxWidth: "250px",
                              height: "100%",
                              width: "100%",
                            }}
                            src={paymeHsbc}
                            alt=""
                          />
                          <Label>{"請用 PayMe 掃描此 PayCode"}</Label>
                          <QrCodeGen url={qrLink} />

                          <Label>{"在交易完成前請不要關閉此頁面"}</Label>
                          <br />
                          <Label>{"如何用 PayMe 付款？"}</Label>
                          <br />
                          <Label>{"1. 打開 PayMe 應用程式"}</Label>
                          <br />
                          <Label>{"2. 掃描 PayCode 以授權付款"}</Label>
                          <br />
                          <Label>{"3. 在 PayMe 完成交易後，請留在此頁面等待確認"}</Label>
                        </div>
                      ) : null}
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>

            {renderPopUp()}

            <div className="submitButtonContainer">
              <ButtonSecondary href={"/cart"}>返回購物車</ButtonSecondary>

              {/* <ButtonPrimary className={'ml-4'} onClick={generateQrCode}>
                    Create QR Code
                  </ButtonPrimary> */}

              <ButtonPrimary type="submit" className={"ml-4"}>
                確認並送出
              </ButtonPrimary>
            </div>
          </form>
        ) : (
          <form
            className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8"
            // method="POST"
            // action={paymentRequestTest}
            // name="payment"
            acceptCharset="utf-8"
            onSubmit={handleRedirectView}
          >
            <h2 className="text-3xl lg:text-4xl font-semibold">{"結算"}</h2>
            <h3 className="text-2xl font-semibold">{"請輸入你的個人資料"}</h3>
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

            <div>
              {/* <h3 className="text-2xl font-semibold">{`你的訂單總金額為 $${total}`}</h3> */}
              <h3 className="text-2xl font-semibold">{"我們接受以下的付款方式:"}</h3>
              <div className="mt-6">
                <Tab.Group>
                  <Tab.List className="flex">
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        <div className="paymentMethodWrapper">
                          <div className="paymentMethod grid grid-cols-2 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                            <img className="border border-neutral-100" src={fps} alt="" />
                            <img className="border border-neutral-100" src={payme} alt="" />
                            <img className="border border-neutral-100" src={master} alt="" />
                            <img className="border border-neutral-100" src={visa} alt="" />
                            <img className="border border-neutral-100" src={weChat} alt="" />
                            <img className="border border-neutral-100" src={aliPay} alt="" />
                          </div>
                        </div>
                      )}
                    </Tab>
                  </Tab.List>
                  <h3 className="text-2xl font-semibold mt-6">{"** 請於付款備註輸入電話號碼以作識別 **"}</h3>

                  <div className="w-100 border-b border-neutral-200 my-5"></div>

                  <Tab.Panels>
                    <Tab.Panel className="space-y-5">
                      <input name="amount" value={total} readOnly hidden />
                      <input name="currency" value="HKD" readOnly hidden />
                      <input name="customer_country" value="HK" readOnly hidden />
                      <input name="customer_postal_code" value="000000" readOnly hidden />
                      <input name="customer_state" value="N/A" readOnly hidden />
                      <div className="space-y-1">
                        <Label>{"聯絡人姓氏"}</Label>
                        <Input
                          type="text"
                          name="customer_last_name"
                          // value={paForm.customer_last_name}
                          // onChange={handlePaFormChange}
                          value={values.customer_last_name}
                          onChange={handleChange}
                          placeholder={"請輸入您的聯絡人姓氏"}
                        />
                      </div>
                      <div className="space-y-1">
                        <Label>{"聯絡人名字"}</Label>
                        <Input
                          type="text"
                          name="customer_first_name"
                          // value={paForm.customer_first_name}
                          // onChange={handlePaFormChange}
                          value={values.customer_first_name}
                          onChange={handleChange}
                          placeholder={"請輸入您的聯絡人名字"}
                        />
                      </div>
                      <div className="space-y-1">
                        <Label>{"郵箱"}</Label>
                        <Input
                          type="text"
                          name="customer_email"
                          // value={paForm.customer_email}
                          // onChange={handlePaFormChange}
                          value={values.customer_email}
                          onChange={handleChange}
                          placeholder={"請輸入您的郵箱"}
                        />
                      </div>
                      <div className="space-y-1">
                        <Label>{"聯絡人電話 (請提供可接受短訊之電話號碼)"}</Label>
                        <Input
                          style={{ backgroundColor: "#e6e6fa" }}
                          name="customer_phone"
                          // value={paForm.customer_phone}
                          // onChange={handlePaFormChange}
                          value={values.customer_phone}
                          onChange={handleChange}
                          placeholder={"請輸入您的聯絡人電話"}
                          disabled
                        />
                      </div>
                      <div className="space-y-1">
                        <Label>{"如何得知這個產品"}</Label>
                        <div className="flex mt-1.5">
                          <Select onChange={handleChInfoChange} value={values.chInfo}>
                            {BuyChInfoList.map((item: any) => {
                              return (
                                <option value={item.value} key={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </Select>
                          {`${values.chInfo}` === "6" && (
                            <Input
                              name="chInfoText"
                              value={values.chInfoText}
                              onChange={handleChange}
                              placeholder={"請註明"}
                            />
                          )}
                        </div>
                      </div>

                      <div
                        className="border-b border-neutral-200 dark:border-neutral-700"
                        style={{ paddingTop: "8px" }}
                      ></div>

                      <div className="space-y-1">
                        <Label>{"送貨地址"}</Label>

                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Select className="mt-1.5" onChange={handleRegionChange} value={values.region}>
                            {regions.map((region: any) => {
                              return (
                                <option defaultValue={region.value} key={region.value}>
                                  {region.value}
                                </option>
                              );
                            })}
                          </Select>

                          <Select className="mt-1.5" onChange={handleDistrictChange} value={values.district}>
                            {values.region === "區域" &&
                              defaultDistrict.map((district: any) => {
                                return (
                                  <option defaultValue={district.value} key={district.value}>
                                    {district.value}
                                  </option>
                                );
                              })}

                            {values.region === "香港島" &&
                              districtHkI.map((district: any) => {
                                return (
                                  <option defaultValue={district.value} key={district.value}>
                                    {district.value}
                                  </option>
                                );
                              })}

                            {values.region === "九龍區" &&
                              districtKl.map((district: any) => {
                                return (
                                  <option defaultValue={district.value} key={district.value}>
                                    {district.value}
                                  </option>
                                );
                              })}

                            {values.region === "新界區" &&
                              districtNt.map((district: any) => {
                                return (
                                  <option defaultValue={district.value} key={district.value}>
                                    {district.value}
                                  </option>
                                );
                              })}
                          </Select>
                        </div>

                        <Input
                          name="customer_address"
                          // value={paForm.customer_address}
                          // onChange={handlePaFormChange}
                          value={values.customer_address}
                          onChange={handleChange}
                          placeholder={"請輸入您的送貨地址"}
                        />
                      </div>

                      <div
                        className="border-b border-neutral-200 dark:border-neutral-700"
                        style={{ paddingTop: "8px" }}
                      ></div>

                      <div className="space-y-1">
                        <Label>{"付款方式"}</Label>
                        <Select className="mt-1.5" value={values.methodOfPayment} onChange={handlePaymentChange}>
                          {options.map((point: any) => {
                            return (
                              <option value={point.value} key={point.value}>
                                {point.value}
                              </option>
                            );
                          })}
                        </Select>

                        <>
                          <Label>{"1. 請輸入以上所有信息"}</Label>
                          <br />
                          <Label>{"2. 確保所有信息正確無誤"}</Label>
                          <br />
                          <Label>{"3. 選擇一種付款方式"}</Label>
                          <br />
                          <Label>{"4. 按確認並發送按鈕"}</Label>
                          <br />
                          <Label>{"5. 您將被重定向到付款頁面"}</Label>
                          <br />
                        </>
                      </div>

                      <input name="network" value={values.methodOfPayment} readOnly hidden />

                      <input
                        name="subject"
                        // value={paForm.customer_email}
                        value={values.customer_email}
                        readOnly
                        hidden
                      />

                      <input
                        name="customer_ip"
                        // value={paForm.customer_ip}
                        value={values.customer_ip}
                        readOnly
                        hidden
                      />

                      <input
                        name="merchant_reference"
                        // value={paForm.merchant_reference}
                        value={values.merchant_reference}
                        readOnly
                        hidden
                      />

                      <input name="return_url" value={returnUrl} readOnly hidden />

                      <input
                        name="sign"
                        // value={paForm.sign}
                        value={values.sign}
                        readOnly
                        hidden
                      />
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>

            {renderPopUp()}

            <div className="submitButtonContainer">
              <ButtonSecondary href={"/cart"}>返回購物車</ButtonSecondary>

              <ButtonPrimary className={"ml-4"} type="submit">
                確認並送出
              </ButtonPrimary>
            </div>
          </form>
        )}
      </>
    );
  };

  const renderPopUp = () => {
    return (
      <Transition appear show={isOpenModalPopUp} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModalPopUp}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div
                  className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full"
                  style={{ height: "25%" }}
                >
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    {!formFilled && (
                      <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                        缺少個人資料
                      </h3>
                    )}
                    {invalidEmail && (
                      <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                        郵箱不合規
                      </h3>
                    )}
                    {invalidPhone && (
                      <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                        電話號碼不合規
                      </h3>
                    )}
                    {soldOut && (
                      <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                        賣光了
                      </h3>
                    )}
                    {insufficientStock && (
                      <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                        庫存不足
                      </h3>
                    )}
                    {payMeServerError && (
                      <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                        服務器錯誤
                      </h3>
                    )}
                    {payMeExpired && (
                      <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                        服務器錯誤
                      </h3>
                    )}
                    {payMeNoStatus && (
                      <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                        服務器錯誤
                      </h3>
                    )}
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalPopUp} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <div
                      className="flex items-center space-x-8"
                      style={{ paddingTop: "3.5rem", paddingBottom: "3.5rem" }}
                    >
                      {!formFilled && (
                        <h3 className={`text-2xl text-neutral-6000`}>
                          {
                            "在提交表格之前，請確認你已填寫聯絡人姓名、郵箱、付款方式、聯繫人電話號碼、如何得知這個產品、完整的發貨地址並附上付款證明"
                          }
                        </h3>
                      )}
                      {invalidEmail && <h3 className={`text-2xl text-neutral-6000`}>{"您的郵箱無效，請重新檢查"}</h3>}
                      {invalidPhone && (
                        <h3 className={`text-2xl text-neutral-6000`}>{"您的電話號碼應為 8 位數字，請再次檢查"}</h3>
                      )}
                      {soldOut && (
                        <h3 className={`text-2xl text-neutral-6000`}>
                          {`抱歉, ${soldOutItem} 目前無貨。請從購物車中取出商品。`}
                        </h3>
                      )}
                      {insufficientStock && (
                        <h3 className={`text-2xl text-neutral-6000`}>
                          {`抱歉, 我們只剩下${insufficientItem.quantity}個${insufficientItem.title}`}
                        </h3>
                      )}
                      {payMeServerError && (
                        <h3 className={`text-2xl text-neutral-6000`}>
                          {"很抱歉，似乎有問題。請稍後再試或使用其他付款方式"}
                        </h3>
                      )}
                      {payMeExpired && (
                        <h3 className={`text-2xl text-neutral-6000`}>
                          {"您的 PayMe 二維碼已過期。請創建一個新代碼並在 10 分鐘內付款。"}
                        </h3>
                      )}
                      {payMeNoStatus && (
                        <h3 className={`text-2xl text-neutral-6000`}>{"抱歉，您的付款狀態無法確認。請聯繫我們。"}</h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderOrderReview = () => {
    return (
      <div className="listingSection__wrap shadow-xl">
        <div className="py-4">
          <span className="text-3xl font-semibold">{"查看我的訂單"}</span>
        </div>

        <CheckoutSummaryRender apiBreakdown={apiBreakdown} cart={cart} />

        {/* SUM */}
        <div className="flex flex-col space-y-4" style={{ margin: "8px" }}>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{"小計:"}</span>
            <span>{`HKD $${subtotal}`}</span>
          </div>
          <div className="flex justify-between font-semibold">
            <span>{"貨費:"}</span>
            <span>{`HKD $${shippingFee}`}</span>
          </div>
          {discountByAmount ? (
            <div className="flex justify-between font-semibold">
              <span>{"優惠券折扣:"}</span>
              <span>{`HKD -$${displayDiscount}`}</span>
            </div>
          ) : null}

          {discountByPercentage ? (
            <div className="flex justify-between font-semibold">
              <span>{"優惠券折扣:"}</span>
              <span>{`-${displayDiscount}%`}</span>
            </div>
          ) : null}

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{"總金額:"}</span>
            <span>{`HKD $${total.toFixed(2)}`}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <main className="container mt-11 mb-24 lg:mb-32 flex justify-center lg:flex-row">
      <Helmet>
        <title>{companyName}</title>
      </Helmet>

      {loading === false && successMesage === undefined && (
        // <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
        //     {renderMain()}
        // </div>

        <main className="mt-11 flex">
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">{renderMain()}</div>

          <div className="hidden lg:block flex-grow">
            <div className="sticky top-24">{renderOrderReview()}</div>
          </div>
        </main>
      )}

      {loading === true && <Spinner className="pt-24 pb-24 lg:py-32" />}

      {successMesage === true && (
        <OneButtonCommonLayout backtHref="/">
          <h2 className="text-3xl lg:text-4xl font-semibold">{`感謝你購買${companyName}產品`}</h2>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <h3 className="text-2xl font-semibold">
            {`你已下單成功，你的訂單編號為：${submissionId}，我們將於3個工作天內為你安排送貨。`}
          </h3>
        </OneButtonCommonLayout>
      )}

      {successMesage === false && (
        <OneButtonCommonLayout backtHref="/">
          <h2 className="text-3xl lg:text-4xl font-semibold">{"好像有錯誤"}</h2>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <h3 className="text-2xl font-semibold">{"您的提交或付款失敗。請再試一次。如果問題仍然存在，請聯繫我們。"}</h3>
        </OneButtonCommonLayout>
      )}
    </main>
  );
};

export default CustomerInfoPage;
