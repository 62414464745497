import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import NcImage from "shared/NcImage/NcImage";
// import ModalPhotos from "containers/ListingDetailPage/ModalPhotos";
import { useParams, useHistory } from "react-router-dom";
import { CartContext } from "context/cart";
import { AuthContext } from "context/authenticate";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Spinner from "components/Spinner/Spinner";
import Banner from "components/Banner/Banner";
// import Label from "components/Label/Label";
// import Select from "shared/Select/Select";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
// import Checkbox from "shared/Checkbox/Checkbox";
import { Helmet } from "react-helmet";

import Axios from "axios";
import { url } from "../../utils/constant";

export interface ListingProductPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const ProductDetailPage: FC<ListingProductPageProps> = ({ className = "", isPreviewMode }) => {
  const { id } = useParams() as any;
  // const location = useLocation() as any;
  const history = useHistory();

  const { companyName } = useContext(AuthContext);
  const { addToCart } = useContext(CartContext);

  // const [isOpen, setIsOpen] = useState(false);
  // const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedSkuList, setSelectedSkuList] = useState<any[]>([]);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [productTotal, setProductTotal] = useState(0);

  const [chosenProduct, setChosenProduct] = useState({
    id: 0,
    seq: 0,
    title: "",
    promotionDescription: "",
    salesPolicy: "",
    description: "",
    description2: "",
    description3: "",
    price: "",
    quantity: "",
    pictureUrls: [],
    skuList: [],
    discountRules: [],
  });
  const [soldOut, setSoldOut] = useState<any>(null);
  const [loaded, setLoaded] = useState<any>(undefined);
  const [isOpenModalPopUp, setIsOpenModalPopUp] = useState(false);

  useEffect(() => {
    getOneProduct(id);
  }, []);

  useEffect(() => {
    setSoldOut(checker(chosenProduct));
  }, [chosenProduct, soldOut]);

  const getOneProduct = async (id: any) => {
    try {
      const result = await Axios.post(`${url}/rapidcart/web/inventory/detail`, { id: id });
      let data = result.data.data;
      let discountRules = data.skuList[0].discountRules || [];
      // console.log("getOne:", data);

      setChosenProduct((prevState) => ({
        ...prevState,
        id: data.id,
        seq: data.seq,
        title: data.title,
        skuList: data.skuList || [],
        promotionDescription: data.promotionDescription || "",
        salesPolicy: data.salesPolicy || "",
        description: data.description,
        description2: data.description2,
        description3: data.description3,
        pictureUrls: data.pictureUrls,
        price: data.skuList[0].salePrice,
        quantity: data.skuList[0].stock,
        skuId: data.skuList[0].id,
      }));

      if (discountRules !== null || discountRules.length > 0) {
        setChosenProduct((prevState) => ({
          ...prevState,
          discountRules: discountRules,
        }));
      }

      if (Array.isArray(data.skuList)) {
        setSelectedSkuList(data.skuList.map((item: any) => ({ ...item, count: 0 })));
      }
      setLoaded(true);
    } catch (err) {
      console.log("Get One Error:", err);
    }
  };

  const getProductTotal = async (selectedSkuList: any) => {
    try {
      let skuListArray: any = [];

      let data = {
        skuList: skuListArray,
      };

      selectedSkuList.forEach((item: any) => {
        if (item.count !== 0) {
          data.skuList.push({
            skuId: item.id,
            count: item.count,
          });
        }
      });

      if (data.skuList.length > 0) {
        const result = await Axios.post(`${url}/rapidcart/web/receipt/calculate`, data);
        // console.log('Calculate Result:', result);

        let total = result.data.data.originTransactionTotal;
        setProductTotal(total);
      } else {
        setProductTotal(0);
      }
    } catch (err) {
      console.log("Calculate Error:", err);
    }
  };

  let formattedTitle = chosenProduct.title.replaceAll(";", "");
  let formattedPromo = chosenProduct.promotionDescription.split("\n");
  let formattedPolicy = chosenProduct.salesPolicy.split("\n");

  let arr = [];

  for (let [key, value] of Object.entries(chosenProduct)) {
    if (key !== undefined && value !== undefined) {
      arr.push(value);
    }
  }

  // let pattern = "https://drive.google.com";
  // let filtered = arr.filter((str: any) => { return str.includes(pattern); })
  // console.log('Filtered:', filtered);

  let picturesArray: any = [];

  if (chosenProduct.pictureUrls !== null) {
    chosenProduct.pictureUrls.map((obj: any) => {
      return picturesArray.push(obj.url);
    });
  }

  const checker = (input: any) => {
    let outOfStock;

    // if (input.quantity === "0" || input.quantity === "" || input.quantity === 0) {
    //   outOfStock = true;
    // } else if (input.quantity === "-1" || input.quantity === -1) {
    //   outOfStock = undefined;
    // } else {
    //   outOfStock = false;
    // }
    const allItemsSoldOut = input.skuList.every((item: any) => item.stock === 0);

    if (allItemsSoldOut) {
      outOfStock = true;
    } else {
      outOfStock = false;
    }

    return outOfStock;
  };

  const redirectToWhatsApp = () => {
    window.location.href = "https://api.whatsapp.com/send?phone=85263672256";
  };

  const closeModalPopUp = () => {
    setIsOpenModalPopUp(false);
  };

  const beforeCarting = () => {
    if (selectedSkuList.every((item) => item.count <= 0)) {
      setIsOpenModalPopUp(true);
      return;
    }

    addToCart({
      ...chosenProduct,
      selectedSkuList,
      id,
    });
    history.push("/cart");
  };

  const onSkuSelectChange = (skuId: number, action: string) => {
    const index = selectedSkuList.findIndex((n: any) => n.id === skuId);
    const count = selectedSkuList[index].count;
    if (action === "increase") {
      // 增加
      setSelectedSkuList(
        selectedSkuList.map((item) => ({
          ...item,
          count: item.id === skuId ? Math.min(item.count + 1, item.stock) : item.count,
        }))
      );
    } else if (action === "decrease") {
      // 减少
      if (count >= 1) {
        setSelectedSkuList(
          selectedSkuList.map((item) => ({
            ...item,
            count: item.id === skuId ? item.count - 1 : item.count,
          }))
        );
      }
    }
  };

  const getSelectedQuantity = () => {
    let subTotal = 0;

    for (let i = 0; i < selectedSkuList.length; i++) {
      subTotal += selectedSkuList[i].count;
    }

    return subTotal;
  };

  useEffect(() => {
    let result = getSelectedQuantity();
    setSelectedQuantity(result);

    getProductTotal(selectedSkuList);
  }, [selectedSkuList]);

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">{formattedTitle}</h2>
        <div className="w-100 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300" style={{ whiteSpace: 'break-spaces' }}>
          <span>{chosenProduct.description}</span>
        </div>

        {chosenProduct.description2 !== "" && (
          <div className="text-neutral-6000 dark:text-neutral-300" style={{ whiteSpace: 'break-spaces' }}>
            <span>{chosenProduct.description2}</span>
          </div>
        )}
        {chosenProduct.description3 !== "" && (
          <div className="text-neutral-6000 dark:text-neutral-300" style={{ whiteSpace: 'break-spaces' }}>
            <span>{chosenProduct.description3}</span>
          </div>
        )}
      </div>
    );
  };

  const renderSkuList = () => {
    if (!chosenProduct || !Array.isArray(chosenProduct.skuList) || !chosenProduct.skuList.length) {
      return null;
    }
    return (
      <div className="listingSection__wrap overflow-hidden">
        <div>
          <h2 className="text-2xl font-semibold">口味選擇</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">請選擇至少一種口味:</span>
        </div>
        <div>
          {selectedSkuList.map((sku: any, index: any) => (
            <div
              className="flex"
              style={{ justifyContent: "space-between", marginBottom: "12px" }}
              key={`sku-${index}`}
            >
              <span className="text-s text-neutral-500 dark:text-neutral-400 font-normal">
                【{sku.name}】 單價$: {sku.salePrice}
              </span>

              {sku.stock === 0 ? (
                <div className="flex items-center justify-center w-28">售罄</div>
              ) : (
                <div className={`nc-NcInputNumber flex items-center justify-between w-28`}>
                  <button
                    className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                    type="button"
                    onClick={() => onSkuSelectChange(sku.id, "decrease")}
                  >
                    <MinusIcon className="w-4 h-4" />
                  </button>

                  <span>{sku.count || 0}</span>

                  <button
                    className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                    type="button"
                    onClick={() => onSkuSelectChange(sku.id, "increase")}
                  >
                    <PlusIcon className="w-4 h-4" />
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPromoDetail = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">{"產品推廣"}</h2>
        <div className="w-100 border-b border-neutral-200 dark:border-neutral-700" />
        {/* CONTENT */}
        <div>
          {chosenProduct.promotionDescription.includes("\n") ? (
            <>
              {formattedPromo.map((bullets: any, index: any) => {
                return (
                  <React.Fragment key={`promo-${bullets}-index-${index}`}>
                    <span className="block mt-3 text-neutral-500 dark:text-neutral-400">{bullets}</span>
                  </React.Fragment>
                );
              })}
            </>
          ) : (
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              {chosenProduct.promotionDescription}
            </span>
          )}

          {/* {
            chosenProduct.discountRules && (
              chosenProduct.discountRules.map((rule: any, index: number) => {
                return (
                  <div
                    className="text-s text-neutral-500 dark:text-neutral-400 font-normal"
                    key={index}
                  >
                    {rule.type === "OVER_AMOUNT_DISCOUNT" ? `每套 ${rule.unitQuantity} 件，總計 HKD $${rule.unitDiscount}` : `如果購買${rule.unitQuantity}個或更多，每個單價是 HKD $${rule.unitDiscount}`}
                  </div>
                );
              })
            )
          } */}
        </div>
      </div>
    );
  };

  const renderPolicy = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">{"條款及細則："}</h2>
        <div className="w-100 border-b border-neutral-200 dark:border-neutral-700" />
        {/* CONTENT */}
        <div>
          {chosenProduct.salesPolicy.includes("\n") ? (
            <>
              {formattedPolicy.map((bullets: any, index: any) => {
                return (
                  <React.Fragment key={`policy-${bullets}-index-${index}`}>
                    <span className="block mt-3 text-neutral-500 dark:text-neutral-400">{bullets}</span>
                  </React.Fragment>
                );
              })}
            </>
          ) : (
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">{chosenProduct.salesPolicy}</span>
          )}
        </div>
      </div>
    );
  };

  const renderPopUp = () => {
    return (
      <Transition appear show={isOpenModalPopUp} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModalPopUp}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div
                  className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full"
                  style={{ height: "25%" }}
                >
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                      缺少字段
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalPopUp} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <div
                      className="flex items-center space-x-8"
                      style={{ paddingTop: "3.5rem", paddingBottom: "3.5rem" }}
                    >
                      您必須選擇所有必需的口味才能繼續
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSection__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {`HKD $${chosenProduct.price}`}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">{"單價"}</span>
          </span>
          {/* <StartRating /> */}
        </div>

        {/* FORM */}
        {/* <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date) => setSelectedDate(date)}
            numberOfMonths={1}
            fieldClassName="p-5"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            fieldClassName="p-5"
            defaultValue={{
              guestAdults: 1,
              guestChildren: 2,
              guestInfants: 0,
            }}
          />
        </form> */}

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{"小計:"}</span>
            <span>{`HKD $${chosenProduct.price}`}</span>
          </div> */}
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{"數量:"}</span>
            <span>{`${selectedQuantity}`}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{"總金額:"}</span>
            {/* <span>{`HKD $${chosenProduct.price}`}</span> */}
            <span>{`HKD $${productTotal}`}</span>
          </div>
        </div>

        {/* SUBMIT */}
        <>
          {soldOut === true && <ButtonSecondary>{"暫時缺貨"}</ButtonSecondary>}
          {soldOut === false && (
            <ButtonPrimary
              onClick={() => {
                beforeCarting();
              }}
            >
              {"加入購物車"}
            </ButtonPrimary>
          )}
          {renderPopUp()}
          {soldOut === undefined && <ButtonSecondary onClick={redirectToWhatsApp}>{"請諮詢"}</ButtonSecondary>}
        </>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingStayDetailPage  ${className}`}
      data-nc-id="ListingStayDetailPage"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>{companyName}</title>
      </Helmet>

      {loaded === undefined && <Spinner className="pt-24 pb-24 lg:py-32" />}

      {loaded === true && (
        <>
          {/* SINGLE HEADER */}

          <>
            <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
              {/* <NcImage
                        className="w-full rounded-xl"
                        containerClassName="container my-10 sm:my-12 "
                        src={PHOTOS[0]}
                      /> */}
              <Banner imageArr={picturesArray} />

              {/* <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2"> */}
              {/* <div
                        className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                        onClick={() => handleOpenModal(0)}
                      >
                        <NcImage
                          containerClassName="absolute inset-0"
                          className="object-cover w-full h-full rounded-md sm:rounded-xl"
                          src={PHOTOS[0]}
                          prevImageHorizontal
                        />
                        <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                      </div> */}

              {/* {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                        <div
                          key={index}
                          className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                            index >= 3 ? "hidden sm:block" : ""
                          }`}
                        >
                          <NcImage
                            containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                            className="object-cover w-full h-full rounded-md sm:rounded-xl "
                            src={item || ""}
                            prevImageHorizontal
                          />
                          <div
                            className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                            onClick={() => handleOpenModal(index + 1)}
                          />
                        </div>
                      ))} */}

              {/* <div
                        className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                        onClick={() => handleOpenModal(0)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                          />
                        </svg>
                        <span className="ml-2 text-neutral-800 text-sm font-medium">
                          {t("ProductsPage.detail.showPics")}
                        </span>
                      </div> */}
              {/* </div> */}
            </header>
            {/* MODAL PHOTOS */}
            {/* <ModalPhotos
                    imgs={PHOTOS}
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    initFocus={openFocusIndex}
                  /> */}
          </>

          {/* MAIn */}
          <main className="container mt-11 flex ">
            {/* CONTENT */}
            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
              {renderSection2()}
              {renderSkuList()}

              {chosenProduct.promotionDescription !== "" && renderPromoDetail()}

              {chosenProduct.salesPolicy !== "" && renderPolicy()}
            </div>

            {/* SIDEBAR */}
            <div className="hidden lg:block flex-grow">
              <div className="sticky top-24">{renderSidebar()}</div>
            </div>
          </main>

          {/* STICKY FOOTER MOBILE */}
          {!isPreviewMode && (
            <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
              <div className="container flex items-center justify-between">
                <span className="text-2xl font-semibold">
                  {`HKD $${chosenProduct.price}`}
                  <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">{"單價"}</span>
                </span>

                <>
                  {soldOut === true && <ButtonSecondary>{"暫時缺貨"}</ButtonSecondary>}
                  {soldOut === false && (
                    <ButtonPrimary
                      onClick={() => {
                        beforeCarting();
                      }}
                    >
                      {"加入購物車"}
                    </ButtonPrimary>
                  )}
                  {soldOut === undefined && <ButtonSecondary>{"請諮詢"}</ButtonSecondary>}
                </>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductDetailPage;
