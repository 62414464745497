import React, { useState } from "react";

interface AnyInterface {
  auth: boolean;
  setAuth: (boolean: boolean) => void;
  userName: string;
  setUserName: (string: string) => void;
  email: string;
  setEmail: (string: string) => void;
  companyName: string;
  setCompanyName: (string: string) => void;
  companyLogo: string;
  setCompanyLogo: (string: string) => void;
  companyEmail: string;
  setCompanyEmail: (string: string) => void;
  companyPhone: string;
  setCompanyPhone: (string: string) => void;
  homePageUrl: string;
  setHomePageUrl: (string: string) => void;
  showNavItems: boolean;
  setShowNavItems: (boolean: boolean) => void;
}

const defaultState = {
  auth: false,
  setAuth: () => {},
  userName: "",
  setUserName: () => {},
  email: "",
  setEmail: () => {},
  companyName: "",
  setCompanyName: () => {},
  companyLogo: "",
  setCompanyLogo: () => {},
  companyEmail: "",
  setCompanyEmail: () => {},
  companyPhone: "",
  setCompanyPhone: () => {},
  homePageUrl: "",
  setHomePageUrl: () => {},
  showNavItems: false,
  setShowNavItems: () => {},
};

const AuthContext = React.createContext<AnyInterface>(defaultState);

const AuthProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = useState<boolean>(false);
  const [userName, setUserName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [companyName, setCompanyName] = useState<any>("");
  const [companyEmail, setCompanyEmail] = useState<any>("");
  const [companyPhone, setCompanyPhone] = useState<any>("");
  const [companyLogo, setCompanyLogo] = useState<any>("");
  const [homePageUrl, setHomePageUrl] = useState<any>("");
  const [showNavItems, setShowNavItems] = useState<boolean>(false);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        userName,
        setUserName,
        email,
        setEmail,
        companyName,
        setCompanyName,
        companyEmail,
        setCompanyEmail,
        companyPhone,
        setCompanyPhone,
        companyLogo,
        setCompanyLogo,
        homePageUrl,
        setHomePageUrl,
        showNavItems,
        setShowNavItems,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
