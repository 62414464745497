import React, { useCallback, useEffect, useState } from "react";
// import { API } from "aws-amplify";
import Axios from "axios";
import { url } from "../utils/constant";

interface googleInterface {
  // deliveryFee: number;
  // discountCodes: any[],
  // discountByRowIndex: any[];
  products: any[];
  setProducts: (products: any) => void;
  checkout?: (orderDetails: any) => void;
  loaded: undefined;
  setLoaded?: (boolean: any) => void;
}

const defaultState = {
  // deliveryFee: 0,
  // discountCodes: [],
  // discountByRowIndex: [],
  products: [],
  setProducts: () => {},
  checkout: () => {},
  loaded: undefined,
  setLoaded: () => {},
};

const GoogleContext = React.createContext<googleInterface>(defaultState);

const GoogleProvider: React.FC = ({ children }) => {
  const [products, setProducts] = useState<Array<object>>([]);
  const [loaded, setLoaded] = useState<any>(undefined);
  // const [deliveryFee, setDeliveryFee] = useState<number>(0);
  // const [discountCodes, setDiscountCodes] = useState<Array<object>>([]);
  // const [discountByRowIndex, setDiscountByRowIndex] = useState<Array<object>>([]);

  useEffect(() => {
    const localData = window.localStorage.getItem("CURRENT-INVENTORY");

    if (localData !== null) {
      setProducts(JSON.parse(localData));
    }
  }, []);

  useEffect(() => {
    fetchAllGoogleProducts();
  }, []);

  const fetchAllGoogleProducts = useCallback(async () => {
    try {
      const result = await Axios.post(`${url}/rapidcart/web/inventory/queryAll`, {
        pageSize: 100000,
      });
      let data = result.data.data.lists;
      // console.log('getAll product:', data);

      setProducts(data);
      setLoaded(true);
    } catch (err) {
      console.log("fetchAllGoogleError:", err);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("CURRENT-INVENTORY", JSON.stringify(products));
  }, [products]);

  return <GoogleContext.Provider value={{ products, setProducts, loaded }}>{children}</GoogleContext.Provider>;
};

export { GoogleContext, GoogleProvider };
