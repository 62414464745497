import React, { FC, useState } from "react";
import './ProgressBar.scss';

export interface ProgressBarProps {
  className?: string;
  done?: string;
};

const ProgressBar: FC<ProgressBarProps> = ({ className = "", done = ""}) => {

  const [style, setStyle] = useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${done}%`
    }

    setStyle(newStyle);
  }, 1100);

  return (
    <div className={`progress`}>
      <div
        className="progressDone"
        style={style}
      >
        {done}%
      </div>
    </div>
  )
}

export default ProgressBar;