import React from "react";
import { Link } from "react-router-dom";
// import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  homePageUrl?: string;
}

const Logo: React.FC<LogoProps> = ({ img = "", imgLight = logoLightImg, className = "", homePageUrl = "" }) => {
  return (
    <>
      {homePageUrl !== "" && (
        <a
          href={homePageUrl}
          className={`ttnc-logo inline-block text-primary-6000 ${className}`}
          style={{ display: "flex", alignItems: "center", height: "80px" }}
        >
          {/* THIS USE FOR MY CLIENT */}
          {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
          {img ? (
            <img
              className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
              style={{ width: "85px", maxHeight: "100%", objectFit: "cover" }}
              src={img}
              alt="Logo"
            />
          ) : (
            ""
          )}
          {imgLight && <img className="hidden max-h-12 dark:block" src={imgLight} alt="Logo-Light" />}
        </a>
      )}

      {homePageUrl === "" && (
        <Link
          to="/"
          className={`ttnc-logo inline-block text-primary-6000 ${className}`}
          style={{ display: "flex", alignItems: "center", height: "80px" }}
        >
          {/* THIS USE FOR MY CLIENT */}
          {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
          {img ? (
            <img
              className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
              style={{ width: "95px", maxHeight: "100%", objectFit: "cover" }}
              src={img}
              alt="Logo"
            />
          ) : (
            ""
          )}
          {imgLight && <img className="hidden max-h-12 dark:block" src={imgLight} alt="Logo-Light" />}
        </Link>
      )}
    </>
  );
};

export default Logo;
